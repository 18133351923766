import axios from 'axios';

import fetchApi from '../../../services/ApiService';

//basic user actions
export const SET_PINS_FILE  ='SET_PINS_FILE'; 
export const SET_ALERT_DATA = 'SET_PIN_IMPORT_ALERT_DATA';
export const SET_SUMMARY_INFO = 'SET_SUMMARY_INFO';
export const SET_SHOW_SUMMARY = 'SET_SHOW_SUMMARY';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_FILE_ERROR = 'SET_FILE_ERROR';
export const SET_RESOURCE_ID = 'SET_RESOURCE_ID';
export const CLEAR_STATE = 'CLEAR_PINS_STATE';

export const UPLOAD_ERROR = 'something went wrong please check page again later';
export const UNAUTHORIZED_ERROR = 'You are not authorized to perform this operation';

export const INVERAL_TIME = 3000;
export const MAX_TIME_LIMIT = 600000;

export const downloadCSV = ()=>{
    return async(dispatch,getState)=>{
        const token = getState().auth.accessToken;
        const id = getState().pinInfo.summaryInfo.id;

        if(token){
            const url = new URL(`${process.env.REACT_APP_ENTITLEMENT_BASE_URL}/pin-imports/${id}`);
            url.searchParams.append('id', id);
            url.searchParams.append('status', 'INVALID');
            let response;
            try{
                response = await fetchApi(url,{
                    method:'GET',
                    headers:{
                        'Authorization': 'Bearer '+token,
                    },
                    responseType: 'blob',
                })

            }catch(e){
            }

            if(response && response.ok){
                const data = await response.blob();
                
                if (!window.navigator.msSaveOrOpenBlob){
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', id+'.csv');
                    document.body.appendChild(link);
                    link.click();
                }else{
                    // BLOB FOR EXPLORER 11
                    window.navigator.msSaveOrOpenBlob(new Blob([data]), id+'.csv');
                }
            }else{
                const statusCode = response && response.status;
                if(statusCode === 403)
                    dispatch(setAlertDATA(UNAUTHORIZED_ERROR));
                else 
                    dispatch(setAlertDATA(UPLOAD_ERROR));
            }
        }
    }
}

export const importPins = ()=>{
    
    return async(dispatch, getState)=>{
        const pinFile = getState().pinInfo.pinFile;
        const token = getState().auth.accessToken;
        dispatch(setSummaryInfo([]));
        dispatch(setShowSummary(false));
        dispatch(setModalData(true,'inprogress'));
        if(token){  
            const url = new URL(`${process.env.REACT_APP_ENTITLEMENT_BASE_URL}/pin-imports`);
            url.searchParams.append('fileName', pinFile.name);

            let res;
            try{
                res = await axios.post(url, pinFile, {
                    headers: {
                        'Authorization': 'Bearer '+token,
                        'Content-Type': 'text/csv'
                      }
                })
                dispatch(setResourceId(res.data.id));

                let  currentTime=0;
                const interval = setInterval(()=>{
                    currentTime += INVERAL_TIME
                    const summaryInfo = getState().pinInfo.summaryInfo;
                    if(typeof(summaryInfo.status) === 'undefined' || summaryInfo.status === 'IN_PROGRESS')
                        if(currentTime>=MAX_TIME_LIMIT)
                            dispatch(setModalData(true,'processing_error'));
                        else
                            dispatch(getSummaryPinImport(res.data.id));
                    else
                        clearInterval(interval);
                },INVERAL_TIME)
                
            }catch(e){
                const statusCode = e.response.status; 
                if(statusCode === 118 || statusCode === 119){
                    let isSizeError =false;
                    let isFormatError =false;
                    if(statusCode === 118){
                        isSizeError = true;
                    }
                    if(statusCode === 119){
                       isFormatError = true;
                    }
                    dispatch(setFileError(isFormatError,isSizeError,true))    
                }else if(statusCode === 415){
                    dispatch(setFileError(true,false,true))
                    dispatch(setModalData(false))
               }else if(statusCode === 403){
                  dispatch(setAlertDATA(UNAUTHORIZED_ERROR));
                  dispatch(setModalData(false))
               }
               else{
                    dispatch(setFileError(true,false,true))
                    dispatch(setModalData(false))
               }
            }
           

        }
    }
}

export const getSummaryPinImport =(id)=>{
    
    return async(dispatch,getState)=>{
        
        const token = getState().auth.accessToken;

        const url = new URL(`${process.env.REACT_APP_ENTITLEMENT_BASE_URL}/pin-imports/${id}/summary`);
        let response;
        url.searchParams.append('id', id);
        
        try{
            response = await fetchApi(url,{
                method:"GET",
                headers:{
                    'Authorization': 'Bearer '+token
                }
            })

        }catch(e){

        }

        if(response && response.ok){
            const data  = await response.json();
            dispatch(setSummaryInfo(data));
            if(data.status.toUpperCase() === 'PROCESSED'){
                dispatch(setModalData(true,'done'));
            }else if(data.status.toUpperCase() === 'PROCESSING_ERROR'){
                dispatch(setModalData(true,'processing_error'));
            }
            
        }else{
            const statusCode = response && response.status;
            if(statusCode === 403)
                dispatch(setAlertDATA(UNAUTHORIZED_ERROR));
            else    
                dispatch(setAlertDATA(UPLOAD_ERROR));
            dispatch(setModalData(false))
        }
    }
}

export const setFileState = (pinFile)=>{
    return {type: SET_PINS_FILE,pinFile}
}

export const setAlertDATA = (alertMsg,alertType='danger')=>{
    return {type: SET_ALERT_DATA,alertMsg,alertType}
}

export const setSummaryInfo = (summaryInfo)=>{
    return {type:SET_SUMMARY_INFO,summaryInfo}
}

export const setShowSummary =(showSummary)=>{
    return {type:SET_SHOW_SUMMARY,showSummary}
}

export const setModalData = (showModal,importStatus='')=>{
    return {type:SET_MODAL_DATA,showModal,importStatus}
}

export const setFileError = (isFormatError,isSizeError,showFileError)=>{
    return {type:SET_FILE_ERROR,isFormatError,isSizeError,showFileError}
}

export const setResourceId = (resourceId)=>{
    return {type:SET_RESOURCE_ID,resourceId}
}

export const clearState =()=>{
    return{type:CLEAR_STATE}
}