import nodeFetch from 'node-fetch';

export const mockFetch = (url, args) => {
  let filePath = '';
  if(url instanceof URL === false) {
    url = new URL(url);
  }
  console.log(url, args);
  filePath = `${process.env.REACT_APP_MOCK_SERVER_URL}` + url.pathname + url.search;
  if(process.env.REACT_APP === 'webdriverio') {
    return nodeFetch(filePath, args);
  }
  return fetch(filePath, args);
};

export const jsFetch = (url, args) => {
  if(process.env.REACT_APP === 'webdriverio') {
    return nodeFetch(url, args);
  }
  return fetch(url, args);
};

export const getApi = () => {
  if(process.env.REACT_APP_ENABLE_FETCH_MOCK === 'true' && (process.env.NODE_ENV !== 'test' || process.env.REACT_APP === 'webdriverio')) { //we will not be using mocking with test or production
    return mockFetch;
  } else {
    return jsFetch;
  }
};

const api = getApi();

export default api;
