import React from 'react';
import {useDispatch} from 'react-redux';

import {setModalData} from './actions'

export default ({replaceArticle,viewStatus,totalCnt,articleExists,isStatusInprogess})=>{
    const dispatch = useDispatch();
    
    let buttonName, modalTitle, modalMsg, ReplaceBtn;
    
    if(totalCnt>0 && articleExists===false)
        buttonName = "Resubmit Ingestion";
    else 
        buttonName = "Replace Article";  

    if(articleExists === true && totalCnt>0)
    {
        if(isStatusInprogess === true){
            modalTitle = 'Ingestion in Progress';
            modalMsg = 'Cannot submit ingestion for PII that is already in progress.'
            ReplaceBtn = false;
        }
        else{
            modalTitle = 'Article already exist in system';
            modalMsg  = 'An article with this PII already exists. What would you like to do?';
        }
    }   
    else if(articleExists === true && totalCnt===0){
        modalTitle = 'Article already exist in system';
        modalMsg = 'An article with this PII already exists. What would you like to do?';
    }
    else{
        if(isStatusInprogess === true){
            modalTitle = 'Ingestion in Progress';
            modalMsg = 'Cannot submit ingestion for PII that is already in progress.'
            ReplaceBtn = false;
        }
        else{
            modalTitle = 'Ingestion History Exists';
            modalMsg = 'Article with this PII does not exist but Ingestion history exists. What would you like to do?'
        }
    }
        
    const closeModal = ()=>{
        dispatch(setModalData(false));
    }

    return(
        <div id="myModal" className="modal1">
            <div className="modal-content1" >
                <div className="modal-body1">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <p className="modal-title1">{modalTitle}</p>
                    <p className="popup-msg">{modalMsg}</p>
                    <div className="modal-btn">
                        <span className="cancel-and-view-inge" onClick={viewStatus}>Cancel and View Ingestion Status</span>
                        
                        {ReplaceBtn !==false && <button
                            type="button"
                            aria-label="Submit PII"
                            className="btn replaceBtn"
                            onClick={replaceArticle}>
                        {buttonName}
                        </button>}
                    </div>
                </div>
            </div>
      </div>
    );
}