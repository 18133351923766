import { combineReducers } from 'redux';
import menu from '../../modules/Menu/reducers';
import auth from '../../modules/Login/reducers';
import pin from '../../modules/PinList/reducers';
import scim from '../../modules/SCIMDemo/reducers';
import user from '../../modules/UserManagement/reducers';
import ingestion from '../../modules/ArticleIngestion/reducers';
import pinInfo from '../../modules/PinImport/reducers';
import acIngest from '../../modules/ACIngest/reducers';

export default combineReducers({
  menu,
  auth,
  pin,
  scim,
  user,
  ingestion,
  pinInfo,
  acIngest
});
