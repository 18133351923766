import React from "react";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const Unavailable = ({path}) => {
  const switches = useSelector(state => state.menu.switches);
  const date = switches.get(path) ? switches.get(path).date : '';
  const rationale = switches.get(path) ? switches.get(path).rationale : '';
  return (
    <div className="row unavailable system-status">
      <div className="col-12 main-message">Sorry for any inconvenience, this feature is currently unavailable.</div>
      <div className="col-12 mt-4">
        <span className="unavailable-additional-information-title title">Additional Information: </span><span className="unavailable-additional-information">{rationale}</span><br/>
        <span className="unavailable-status-date-title title">Status Date: </span><span className="unavailable-status-date">{date}</span>
      </div>
    </div>
  );
};

Unavailable.propTypes = {
  path: PropTypes.string
};

export default Unavailable;
