import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SITE_FILTER_NAME, SITE_TAG, DUPLICATE_PIN_ERROR, clearFilters, removeFilter, addFilter, getPinList, setError } from './actions';


export default () => {
  const sites = useSelector(state => state.pin.filters.site, () => {});
  const filters = [];
  const dispatch = useDispatch();
  const [site, setSite] = useState();

  const addSiteHandler = () => {
    if(sites.indexOf(site) === -1) {
      dispatch(addFilter(SITE_FILTER_NAME, site));
      dispatch(getPinList());
      setSite('');
    } else {
      dispatch(setError(DUPLICATE_PIN_ERROR));
    }
  };

  const removeSiteHandler = (site) => {
    dispatch(removeFilter(SITE_FILTER_NAME, site));
    dispatch(getPinList());
    setSite('');
  };

  const clearSitesHandler = () => {
    dispatch(clearFilters());
    dispatch(getPinList());
  };

  const handleInputChange = (e) => {
    const {value} = e.target;
    setSite(value);
  };

  const handleInputKeyUp = (event) => {
    if(event.key === 'Enter'){
      addSiteHandler();
    }
  };


  sites.forEach((item) => {
    filters.push(
      <div className="d-inline-flex border border-dark p-1 px-3 mr-2 pin-filter__site" key={item}>
        <span className="m-1"><span className="pin-filter__tag-header">{SITE_TAG}</span> <span className="pin-filter__tag-value">{item}</span></span>
        <button
          type="button"
          className="close"
          onClick={() => removeSiteHandler(item)}
          aria-label="Close"
        >
          <span className="close-sign" aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  });


  return (
    <>
      <div className="row">
        <div className="col-12 input-group mb-2 pin-filter clearfix">
          <div className="input-group-prepend">
            <span className="input-group-text pin-filter__label">Site </span>
          </div>
          <input
            type="text"
            name="site"
            className="form-control pin-filter__input"
            aria-label="Site"
            onChange={handleInputChange}
            onKeyUp={handleInputKeyUp}
            value={site || ''}
            spellCheck="false"
          />
          <div className="input-group-append">
            <button
              type="button"
              aria-label="Add Site"
              className="btn btn-primary pin-filter__button"
              onClick={addSiteHandler}
            >
              Add Site
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        {
          filters.length > 0 &&
          <div className="col-12 pin-selected-filter">
            {filters}
            <div className={(filters.length < 2 ? 'd-none ' : 'd-inline-flex ') + ' border border-dark p-1 px-3 mr-2 pin-filter__clear-filters'}>
              <span className="m-1"><span className="pin-filter__tag-header pin-filter__clear-all">Remove All Sites</span></span>
              <button
                type="button"
                className="close"
                onClick={() => clearSitesHandler()}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
};
