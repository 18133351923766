import React from "react";
import PinList from '../../modules/PinList';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getPinList, readSettingsFromLocalStorage } from '../../modules/PinList/actions';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readSettingsFromLocalStorage());
    dispatch(getPinList());
  });

  return <>
    <h2 className="page-title">Lost or Damaged Pins</h2>
    <PinList></PinList>
  </>;
};
