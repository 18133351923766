const buildMap = new Map();

if(process.env.REACT_APP_SWITCH_OFF_USER_MANAGEMENT === 'true') {
  buildMap.set('/user-management', {
    "rationale": "This interface is still under construnction and will be lauched soon.",
    "date": "12 Dec 2019"
  });
}

if(process.env.REACT_APP_SWITCH_OFF_ARTICLE_INGESTION === 'true') {
  buildMap.set('/article-ingestion', {
    "rationale": "This interface is still under construnction and will be lauched soon.",
    "date": "-"
  });
}

if(process.env.REACT_APP_SWITCH_OFF_AC_INGESTION === 'true') {
  buildMap.set('/ac-ingest', {
    "rationale": "This interface is still under construnction and will be lauched soon.",
    "date": "-"
  });
}

module.exports = (key) => {
  return buildMap.get(key);
};
