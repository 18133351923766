import React from "react";
import { Link } from "react-router-dom";
import './index.scss';
import LogoutButton from '../Login/LogoutButton';
import StatusLink from '../SystemStatus/StatusLink';


const Header = () => {
  return (
    <header className="container znr-header">
      <div className="row">
        <div className="col-6 mr-auto">
          <Link to="/">
            <img className="znr-logo" src="/assets/images/logo.svg" alt="Zener"/>
          </Link>
        </div>
        <div className="col-auto">
          <StatusLink /> &nbsp;&nbsp;
          <LogoutButton />
        </div>
      </div>
    </header>
  );
};

export default Header;
