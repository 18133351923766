import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from './modules/Header';
import Footer from './modules/Footer';
import Menu from './modules/Menu';
import Routes from './routes';
import { Provider } from 'react-redux';
import store from './store';
import './index.scss';

const App = () => {

  return (
    <Provider store={store}>
      <input id="software-version" name="software-version" type="hidden" value={process.env.REACT_APP_VERSION} />
      <Router>
        <Header></Header>
        <Menu></Menu>
        <main className="container znr-main">
          <Routes>
          </Routes>
        </main>
        <Footer></Footer>
      </Router>
    </Provider>
  );
};

export default App;
