import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import {getPinList, PIN_STATUS_INITIAL, PINS_PER_PAGE} from './actions';
import Details from './Details';
import Controls from './Controls';
import Filters from './Filters';
import Error from '../Utils/Error';
import Pagination from '../Utils/Pagination';
import './index.scss';

export default () => {
  const error = useSelector(state => state.pin.error);
  const totalCount = useSelector(state => state.pin.totalCount);
  const dispatch = useDispatch();

  const pageChanged = (pageNo) => {
    dispatch(getPinList(PIN_STATUS_INITIAL, null, pageNo));
  };

  return (
    <>
      <Filters />
      {error &&
        <div className="row pin-error mt-3">
          <div className="col-12">
            <Error error={error} />
          </div>
        </div>
      }
      <div className="row mt-3"><Details/></div>
      <div className="pin-controls border-top border-secondary row sticky bottom bg-white py-3">
        <div className="col-6 justify-content-left">
          <Pagination pageChanged={pageChanged} totalPages={Math.ceil(totalCount/PINS_PER_PAGE)} />
        </div>
        <div className="col-6">
          <Controls />
        </div>
      </div>
    </>
  );
};
