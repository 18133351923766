import React from "react";

const Loading = () => {
  return (
    <>
      <div className="spinner-border text-primary loading" role="status" />
      <br/>
      Loading...
    </>
  );
};
export default Loading;
