import React from 'react';
import { useEffect } from 'react';
import {useDispatch} from 'react-redux';

import ACIngest from '../../modules/ACIngest';
import Unavailable from '../../modules/SystemStatus/Unavailable';
import {clearState} from '../../modules/ACIngest/actions';


export default ({location})=>{

    const dispatch = useDispatch();
    useEffect(() => {
        return function cleanUpState(){
            dispatch(clearState());
        };
    });
    
    return(
        <div>
            <h2 className="page-title">Ancillary Content Ingestion</h2>
            {process.env.REACT_APP_SWITCH_OFF_AC_INGESTION === 'true' ? <Unavailable path={location.pathname} /> : <ACIngest />}
        </div>
    );

}