import fetchApi from '../../../services/ApiService';

//basic user actions
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_ENTITLEMENT_LIST = 'SET_ENTITLEMENT_LIST';
export const SET_PIN_LOADING = 'SET_USER_PIN_LOADING';
export const SET_PIN_ERROR = 'SET_USER_SEARCH_ERROR';
export const SET_USER_INFO = 'SET_USER_INFO';
export const CLEAR_STATE = 'CLEAR_USER_STATE';

export const USER_SEARCH_ERROR = 'something went wrong please check page again later';

export const getUserList = (mailid='')=>{
      
      return async(dispatch, getState)=>{
        
        const token = getState().auth.accessToken;
        
        if(token){
            dispatch(setLoading());
            
            const url = new URL(`${process.env.REACT_APP_SCIM_USER_INFO_URL}`);
            //url.searchParams.append('limit', '20');
            //url.searchParams.append('offset', '0');
            
            /*if(mailid)
                url.searchParams.append('email_like', mailid);*/
            
            const data = {
                "filter": `email co ${mailid}`,
                "startIndex": 1,
                "count": 20 
            }
            
            let response;
            try {
              response = await fetchApi(url, {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer '+token,
                  'Content-Type': 'application/json'
                },
                body : JSON.stringify(data)
              });
            } catch(e) {
      
            };
            if(response && response.ok) {
              const data = await response.json();
              if(data.totalResults>20)
                dispatch(setError('Max number of results exceeded. The system can return only 20. Please be more specific with your search criteria.'));
              else{
                const userIdList = data.Resources.map((val)=>{
                  return val.id;
                })
                const results = await getEntitlementCount(userIdList,token);
                data.Resources.forEach((val)=>{
                  val.permission = results[val.id]
                })
                
                dispatch(setUserList(data.Resources, data.totalResults));
              }      
            }else{
              dispatch(setError(USER_SEARCH_ERROR));
            } 
        }
    }
}

export const getEntitlementCount = async(userIdList,token)=>{

  let errorCode=0;
  const promises = userIdList.map(async(userId)=>{
    
    const url = new URL(`${process.env.REACT_APP_ENTITLEMENT_BASE_URL}/entitlements`);
    url.searchParams.append('limit', '20');
    url.searchParams.append('offset', '0');
    url.searchParams.append('userId', userId);
    
    let response;
    try {
      response = await fetchApi(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+token,
        }
      });
    } catch(e) {

    };
    if(response && response.ok) {
      let data =[];
      data = await response.json();
      return {
        userId: userId,
        total:data.total
      }
    }else{
      errorCode = 1;
    } 

  })

  
  // wait until all promises resolve
  const results = await Promise.all(promises);
  const finalResult = [];

  if(errorCode !==1)
  {
    results.forEach((val,key)=>{
      finalResult[val.userId] = val.total;
    })
  }
  
  return finalResult;
}

export const getEntitlements = (userId)=>{
  
  return async(dispatch, getState)=>{
        
    const token = getState().auth.accessToken;
    
    if(token){
        dispatch(setLoading());
        const statusList = ['Active','Inactive'];
        const promises = statusList.map(async(status)=>{
          
          const url = new URL(`${process.env.REACT_APP_ENTITLEMENT_BASE_URL}/entitlements`);
          url.searchParams.append('limit', '20');
          url.searchParams.append('offset', '0');
          url.searchParams.append('userId', userId);
          url.searchParams.append('status', status);
          let response;
          try {
            response = await fetchApi(url, {
              method: 'GET',
              headers: {
                'Authorization': 'Bearer '+token,
              }
            });
          } catch(e) {
      
          };
          if(response && response.ok) {
              const data = await response.json();
              return data.entitlement;              
          }else{
            dispatch(setError(USER_SEARCH_ERROR));
          }
        })

        try{
          const result = await Promise.all(promises);
          const entitlements = [...result[0],...result[1]];
          
          //sort by redeemDate
          entitlements.sort((a,b)=>{
            return b.redeemDate>=a.redeemDate?1:-1;   
          })
          dispatch(setEntitlementList(entitlements));
        }catch(e){
          dispatch(setError(USER_SEARCH_ERROR));
        }

      } 
  }
}

export const getUserInfo = (userId)=>{
  
  return async(dispatch, getState)=>{
        
    const token = getState().auth.accessToken;
    
    if(token){
        dispatch(setLoading());
        const url = new URL(`${process.env.REACT_APP_SCIM_USER_INFO_URL}/${userId}`);
      
        let response;
        try {
          response = await fetchApi(url, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer '+token,
            }
          });
        } catch(e) {
    
        };
        if(response && response.ok) {
            const data = await response.json();
            const userInfo = {
              email:data.emails[0].value,
              givenName : data.name.givenName,
              familyName : data.name.familyName,
              created : data.meta.created,
              emailVerified : data.meta.emailVerified
            }
            dispatch(setUserInfo(userInfo));
        }else{
          dispatch(setError(USER_SEARCH_ERROR));
        }

      } 
  }
}

export const setError = (error) => {
  return { type: SET_PIN_ERROR, error };
};

export const setUserList = (users, totalCount = 0) => {
    return { type: SET_USER_LIST, users, totalCount };
};

export const setEntitlementList = (entitlements)=>{
  return { type: SET_ENTITLEMENT_LIST, entitlements};
}

export const setUserInfo=(userInfo)=>{
  return { type: SET_USER_INFO, userInfo};
}

export const setLoading = ()=>{
    return{type:SET_PIN_LOADING}
}

export const clearState=()=>{
  return{type:CLEAR_STATE}
}