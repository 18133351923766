import {SET_PINS_FILE,SET_ALERT_DATA,SET_SUMMARY_INFO,SET_SHOW_SUMMARY,SET_MODAL_DATA,SET_FILE_ERROR,SET_RESOURCE_ID,CLEAR_STATE} from '../actions';

export const initialState={
    pinFile:[],
    showsummary:false,
    summaryInfo:[],
    resourceId:'',
    fileError:{
        isFormatError:false,
        isSizeError:false,
        showFileError:false
    }
}

export default (state={...initialState},action={})=>{
    switch(action.type){
        
        case SET_PINS_FILE :
            return {
                ...state,
                pinFile: action.pinFile,
            };  

        case SET_ALERT_DATA:
            return{
                ...state,
                alertMsg:action.alertMsg,
                alertType:action.alertType,
            }
            
        case SET_SUMMARY_INFO:
            return {
                ...state,
                summaryInfo:action.summaryInfo,
            }
            
        case SET_SHOW_SUMMARY:
            return{
                ...state,
                showSummary:action.showSummary
            } 
            
        case SET_MODAL_DATA:
            return{
                ...state,
                showModal:action.showModal,
                importStatus:action.importStatus
            }

        case SET_FILE_ERROR :
            return{
                ...state,
                fileError : {
                    ...state.fileError,
                    isFormatError:action.isFormatError,
                    isSizeError:action.isSizeError,
                    showFileError:action.showFileError
                }
            }
        
        case  SET_RESOURCE_ID :
            return{
                ...state,
                resourceId : action.resourceId
            }
            
        case CLEAR_STATE:
            return  initialState;
    
        default:
            return state; 
    }
}