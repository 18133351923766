import React, {useState} from "react";
import { useSelector } from 'react-redux';
import StatusModal from './StatusModal';
import './index.scss';

export default () => {
  const items = useSelector(state => state.menu.items), switches = useSelector(state => state.menu.switches), cards = [];
  let count = 0;
  const [showStatus, setShowStatus] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState({});

  const handleShowStatus = (item) => {
    setShowStatus(true);
    setSelectedMenu(item);
  };

  Object.values(items).forEach(function (item) {
    const switchInfo = switches.get(item.id) ? switches.get(item.id) : [];
    count++;
    cards.push(
      <div className={'card' + (count%4 === 0 ? ' last' : '')} key={item.id}>
        <div className="card-header bg-white txt-light">
          {item.name}
        </div>
        <div className="card-body text-center">
          <span className="up-txt">{switchInfo.upCount ? switchInfo.upCount : 0}</span><span className="total-txt">&nbsp;/&nbsp;{switchInfo.totalCount ? switchInfo.totalCount : 0}</span>
          <p>successfully deployed</p>
        </div>
        <div className="card-footer status bg-white ">
          <button
            onClick={switchInfo.upCount !== switchInfo.totalCount ? (() => handleShowStatus(item)): null}
            className={"btn text-white " +
              (switchInfo.upCount === switchInfo.totalCount ? 'bg-green' : '') +
              (switchInfo.upCount < switchInfo.totalCount && switchInfo.upCount !== 0 ? 'bg-yellow' : '') +
              (switchInfo.upCount === 0 ? 'bg-red' : '')
            }>
            {switchInfo.upCount === switchInfo.totalCount ? 'Operational' : 'View Details'}
          </button>
        </div>
      </div>
    );
  });



  return (
    <div className="row main system-status">
      {cards}
      <StatusModal show={showStatus}  setShow={setShowStatus} item={selectedMenu} />
    </div>
  );
};
