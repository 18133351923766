import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';
import AuthService from '../services/AuthService';

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware
  )
);
AuthService.setLogin(store.dispatch);

export default store;
