import fetchApi from '../../../services/ApiService';

//basic user actions
export const SET_AC_INGEST_FILE= 'SET_AC_INGEST_FILE';
export const SET_ERROR = 'SET_AC_ERROR';
export const SET_MODAL_DATA = 'SET_AC_MODAL_DATA';
export const SET_UPLOAD_STATUS = 'SET_AC_UPLOAD_STATUS';
export const SET_AUDIENCE = 'SET_AC_AUDIENCE';
export const SET_FILE_ID='SET_AC_FILE_ID';
export const SET_FIELD_ERROR='SET_AC_FIELD_ERROR';
export const SET_SUMMARY_INFO = 'SET_AC_SUMMARY_INFO';
export const SET_SHOW_SUMMARY = 'SET_AC_SHOW_SUMMARY';
export const SET_RESOURCE_ID = 'SET_AC_RESOURCE_ID';
export const CLEAR_STATE = "CREAR_AC_STATE";

export const API_ERROR = 'something went wrong please check page again later';
export const UNAUTHORIZED_ERROR = 'You are not authorized to perform this operation';

export const INVERAL_TIME = 3000;
export const MAX_TIME_LIMIT = 600000;

export const uploadACIngestFile = (id)=>{

    return async(dispatch,getState)=>{
        
        const acIngestFile = getState().acIngest.acIngestFile;
        const token = getState().auth.accessToken;
        
        if(token){
            dispatch(setModalData(true,'inprogress','upload'));
            const url = new URL(`${process.env.REACT_APP_CONTENT_BASE_URL}/presigned-urls`);
            const data = {
                fileName:acIngestFile.name
            }

            let response;            
            try{
                response = await fetchApi(url,{
                    method:"POST",
                    headers:{
                        'Authorization': 'Bearer '+token,
                        'Content-Type': 'application/json'
                    },
                    body:JSON.stringify(data)
                })

            }catch(e){

            }

            if(response && response.ok){
                const data  = await response.json();
                dispatch(setFileId(data.fileId));
                await dispatch(putFileInBucket(data.presignedUrl));
            }else{
                const statusCode = response && response.status;
                
                const  error = statusCode === 403? UNAUTHORIZED_ERROR: API_ERROR;
                dispatch(setError(error));
                dispatch(setModalData(false));
            }

        }
    }
}

export const putFileInBucket= (presignedUrl)=>{

    return async(dispatch,getState)=>{
        
        const acIngestFile = getState().acIngest.acIngestFile;
        const url = new URL(`${presignedUrl}`);
        let response;            
        try{
            response = await fetchApi(url,{
                method: 'PUT',
               body: acIngestFile
            })

        }catch(e){

        }

        if(response && response.ok){
            dispatch(setModalData(true,'done','upload'));
            dispatch(setUploadStatus(true));
           
        }else{
            dispatch(setError(API_ERROR));
            dispatch(setModalData(false));
        }
    }
}

export const ingestACFile = ()=>{

    return async(dispatch,getState)=>{
        
        const token = getState().auth.accessToken;
        const fileId = getState().acIngest.fileId;
        const audience = getState().acIngest.audience;
        
        if(token){
            dispatch(setSummaryInfo([]));
            dispatch(setModalData(true,'inprogress','ingest'));
            const url = new URL(`${process.env.REACT_APP_CONTENT_BASE_URL}/ancillary-contents-ingestions`);
            const data = {
                fileId:fileId,
                category:audience
            }   

            let response;            
            try{
                response = await fetchApi(url,{
                    method:"POST",
                    headers:{
                        'Authorization': 'Bearer '+token,
                        'Content-Type': 'application/json'
                    },
                    body:JSON.stringify(data)
                })

            }catch(e){

            }

            if(response && response.ok){
                const data  = await response.json();
                dispatch(setResourceId(data.id));
                
                let  currentTime=0;
                const interval = setInterval(()=>{
                    currentTime += INVERAL_TIME
                    const summaryInfo = getState().acIngest.summaryInfo;
                    if(typeof(summaryInfo.status) === 'undefined' || summaryInfo.status === 'IN_PROGRESS'){
                        
                        if(currentTime>=MAX_TIME_LIMIT){
                            dispatch(setModalData(true,'processing_error','ingest'));
                            clearInterval(interval);
                        }
                        else
                            dispatch(getSummaryInfo(data.id));
                    }
                    else
                        clearInterval(interval);
                },INVERAL_TIME)
                
            }else{
                dispatch(setError(API_ERROR));
                dispatch(setModalData(false));
            }

        }
    }
}

export const getSummaryInfo =(ingestionId)=>{
    
    return async(dispatch,getState)=>{
        
        const token = getState().auth.accessToken;

        const url = new URL(`${process.env.REACT_APP_CONTENT_BASE_URL}/ancillary-contents-ingestions/${ingestionId}`);
        let response;
        url.searchParams.append('ingestion-id', ingestionId);
        
        try{
            response = await fetchApi(url,{
                method:"GET",
                headers:{
                    'Authorization': 'Bearer '+token
                }
            })

        }catch(e){

        }

        if(response && response.ok){
            const data  = await response.json();
            dispatch(setSummaryInfo(data));
            
            if(data.status.toUpperCase() === 'PROCESSED'){
                dispatch(setModalData(true,'done','ingest'));
                dispatch(setUploadStatus(false))
            }else if(data.status.toUpperCase() === 'PROCESSING_ERROR'){
                dispatch(setModalData(true,'processing_error','ingest'));
            }
            else if(data.status.toUpperCase() === 'INVALID_INPUT'){
                dispatch(setModalData(true,'invalid_input','ingest'));
            }
            
        }else{
            const statusCode = response && response.status;
            if(statusCode === 403)
                dispatch(setError(UNAUTHORIZED_ERROR));
            else    
                dispatch(setError(API_ERROR));
            dispatch(setModalData(false))
        }
    }
}


export const setFileState = (acIngestFile)=>{
    return {type: SET_AC_INGEST_FILE,acIngestFile}
}

export const setError = (error,errorVariant='danger')=>{
    return {type: SET_ERROR,error,errorVariant}
}

export const setModalData = (showModal,ImportStatus='',importType='')=>{
    return {type:SET_MODAL_DATA,showModal,ImportStatus,importType}
}

export const setUploadStatus = (uploadStatus)=>{
    return {type:SET_UPLOAD_STATUS,uploadStatus}
}

export const setAudience = (audience)=>{
    return {type:SET_AUDIENCE,audience}
}

export const setFileId= (fileId)=>{
    return{type:SET_FILE_ID,fileId}
}

export const setFieldError = (errorMsg,fieldnName)=>{
    return{type:SET_FIELD_ERROR,errorMsg,fieldnName}
}

export const setSummaryInfo = (summaryInfo)=>{
    return{type:SET_SUMMARY_INFO,summaryInfo}
}

export const setShowSummary=(isShowSummary)=>{
    return{type:SET_SHOW_SUMMARY,isShowSummary}
}

export const setResourceId = (resourceId)=>{
    return {type:SET_RESOURCE_ID,resourceId}
}

export const clearState =()=>{
    return{type:CLEAR_STATE}
}

