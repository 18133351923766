import React, {useState} from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const MenuItem = ({ item, level = 0, displayChildren = false }) => {

  const childrenItems = [];
  const [showToolTip, setShowToolTip] = useState(false);

  if(item.children && displayChildren === true) {
    Object.values(item.children).forEach(function (child, key) {
      childrenItems.push(
        <MenuItem key={child.id} item={child} level={level+1} displayChildren={true}/>
      );
    });
  }

  const handleShowToolTip = (e) => {
    e.stopPropagation();
    setShowToolTip(true);
  };

  const handleHideToolTip = (e) => {
    e.stopPropagation();
    setShowToolTip(false);
  };

  const infoIcon = item.description ? <img className="menu-info" onMouseEnter={handleShowToolTip} onMouseLeave={handleHideToolTip} alt='menu information' src='/assets/icons/gizmo-information.svg'/> : null;
  const menuIcon = item.icon ? <img className="menu-icon" alt={item.name} src={item.icon} /> : null;
  const itemClass = 'level-' + level + (childrenItems.length ? ' pb-1 title' : '') + (item.icon ? ' with-icon' : '');
  return (
    <React.Fragment>
      { item.url
        ? <Link id={item.htmlId} className={itemClass} to={item.url}>{menuIcon}{item.name}{infoIcon}</Link>
        : <span id={item.htmlId} className={itemClass}>{menuIcon}{item.name}{infoIcon}</span>
      }
      { item.description && showToolTip ? <div className='show tooltip' role="tooltip"><div className="tooltip-inner">{item.description}</div></div> : null }
      { childrenItems }
    </React.Fragment>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  level: PropTypes.number,
  displayChildren: PropTypes.bool,
};


export default MenuItem;
