import { UserManager, WebStorageStateStore } from 'oidc-client';
import querystring from 'querystring';
import { setAuth, clearAuth, loginInProcessing } from '../../modules/Login/actions';


const getConfig = () => {
  return {
    "authority": `${process.env.REACT_APP_AUTH_AUTHORITY}`,
    "client_id": `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
    "redirect_uri": `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
    "post_logout_redirect_uri": `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
    "response_type": "code",
    "scope": "openid profile",
    "filterProtocolClaims": false,
    "loadUserInfo": true,
    "silent_redirect_uri": `${process.env.REACT_APP_AUTH_SILENT_REDIRECT_URI}`,
    "automaticSilentRenew": true,
    "accessTokenExpiringNotificationTime": 60,
    "extraQueryParams": { //api names
      "audience": `${process.env.REACT_APP_AUTH_AUDIENCE_URL}`
    },
    "userStore": new WebStorageStateStore({ store: window.localStorage }),
  };
};

const oidcConfig = getConfig();
const znrUserManager = new UserManager(oidcConfig);


znrUserManager.events.addAccessTokenExpiring(function(){
  console.log("token expiring...");
});

znrUserManager.events.addAccessTokenExpired(function(){
  console.log("token expired...");
  AuthService.logout();
});

znrUserManager.events.addSilentRenewError(function (e) {
  console.log("silent renew error", e.message);
});

znrUserManager.events.addUserLoaded(function (user) {
  console.log("user loaded");
  AuthService.setAccessTokenInfo();
});

znrUserManager.events.addUserUnloaded(function (e) {
  console.log("user cleared");
});

const logout = async () => {
  await znrUserManager.removeUser();
  await znrUserManager.clearStaleState();
  znrUserManager.dispatch(clearAuth());
  window.location.assign(`${process.env.REACT_APP_AUTH_SILENT_LOGOUT_URI}`);
};

const initLogin = async () => {
  await znrUserManager.signinRedirect();
};

const completeLogin = async () => {
  znrUserManager.dispatch(loginInProcessing());
  await znrUserManager.signinRedirectCallback();
};


const setAccessTokenInfo = async () => {
  const user = await znrUserManager.getUser();
  if (!user || !user.access_token) {
    return false;
  }
  
  const accessToken = user.access_token;
  const userId = user.profile.sub;
  znrUserManager.dispatch(setAuth(accessToken, userId));
};

const isLoginRedirect = () => {
  const queryParams = querystring.decode(window.location.search.replace('?', ''));
  return Object.keys(queryParams).length === 2 && queryParams.code !== '' && queryParams.state !== '' && window.location.pathname === '/login' ? true : false;
};

const setLogin = async (dispatch) => {
  znrUserManager.dispatch = dispatch;
  const accessToken = getCookie('znrAuth0Tkn');
  const userId = getCookie('znrAuth0UsrId');
  if(accessToken && userId) {
    znrUserManager.dispatch(setAuth(accessToken, userId));
    return;
  }
  else if(AuthService.isLoginRedirect() === true) {
    await AuthService.completeLogin();
  }
  await AuthService.setAccessTokenInfo();
};

const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const AuthService = {
  initLogin,
  logout,
  setLogin,
  getConfig,
  completeLogin,
  setAccessTokenInfo,
  isLoginRedirect,
  getCookie
};

export default AuthService;
