import React from "react";
import './index.scss';


const Footer = () => {
  return (
    <footer className="container znr-footer">
      <div className="row mt-5 pt-4 pb-4 footer-row border-primary">
        <div className="col-2">
          <img className="znr-logo-elsevier" src="/assets/images/logo-elsevier-wordmark.svg" alt="Elsevier"/>
        </div>
        <div className="col-8 copyright">
          Copyright &copy; 2019 Elsevier
        </div>
        <div className="col-2">
          <img className="znr-logo-relx" src="/assets/images/logo-relxgroup.svg" alt="Relx"/>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
