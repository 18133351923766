import React from "react";
import MenuItem from './MenuItem';
import PropTypes from 'prop-types';

const MenuDetails = ({ setSelectedMenuId, parentId = '', showMenu = false, items }) => {
  const childrenItems = [];

  if (items !== undefined) {
    Object.values(items).forEach(function (item, key) {
      childrenItems.push(
        <div className="col-4" key={item.id}>
          <MenuItem item={item} level={1} displayChildren={true} />
        </div>
      );
    });
  }

  return (
    <div
      onMouseEnter={() => setSelectedMenuId(parentId)}
      onMouseLeave={() => setSelectedMenuId('')}
      className={ (showMenu ? '' : 'd-none') + ' col-12 pl-4 pr-4 pt-3 pb-3 position-absolute border border-top-0 bg-light'}>
      <div className="row">
        {childrenItems}
      </div>
    </div>
  );
};

MenuDetails.propTypes = {
  setSelectedMenuId: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  showMenu: PropTypes.bool,
  items: PropTypes.array,
};

export default MenuDetails;
