import React from 'react';

export default({name})=>{

    return(
    <div className="col input-group mb-2 clearfix search row">
        
        <input
            type="text"
            name={`${name}-search`}
            className={`form-control ${name}-search`}
            aria-label="isbn-search"
            placeholder={`Search typing ${name}`}
            autoComplete="off"
        />
        <div className="input-group-prepend">
            <span className="input-group-text search-icon"></span>
        </div>
    </div>
    );
}