import React from "react";
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';

import UserManagement from '../../modules/UserManagement';
import Unavailable from '../../modules/SystemStatus/Unavailable';
//import {getUserList} from '../../modules/UserManagement/actions';
import {clearState} from '../../modules/UserManagement/actions';

const UserManagementRoute = ({location}) => {
  
  const dispatch = useDispatch();
  useEffect(()=>{
    //dispatch(getUserList());
    
    return function cleanUpState(){
      dispatch(clearState());
    }

  })
  
  return <>
    <h2 className="page-title">User Management</h2>
    {process.env.REACT_APP_SWITCH_OFF_USER_MANAGEMENT === 'true' ? <Unavailable path={location.pathname} /> : <UserManagement />}
  </>;
};

UserManagementRoute.propTypes = {
  location: PropTypes.object
};

export default UserManagementRoute;
