import React from 'react';

import AutoComplete from './AutoComplete';

export default()=>{

    return(
        <div className="add-permission">
            
            <div className="row">
                <div className="col-12" id="add-permissions-label">Add Permissions
                </div>
            </div>    
            <div className="row">
                <div className="col-10">
                    <AutoComplete name="ISBN"/>
                </div>
                <div className="col-2">
                    <button className="search-btn">Search</button>
                </div>
            </div>
        </div>    
    );
}

