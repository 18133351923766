import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './actions';

export default () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return isAuthenticated ? (
    <button
      className="btn btn-primary logout-btn"
      onClick={() => dispatch(logout())}>
      Logout
    </button>
  ) : null;
};
