import React from 'react';
import ReactDOM from 'react-dom';
import App from './client/App';

const createApp = () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
};

createApp();

export default createApp;
