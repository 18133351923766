import React,{useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import Modal from './Modal';
import Alert from '../Utils/AlertDismissible';
import { getIngestionData, setError, submitIngestion, setModalData, setAlertDATA} from "./actions";


export default () => {
  
  const dispatch = useDispatch();
  const PIIError = useSelector(state=>state.ingestion.PIIError);
  const [PIINumber, setPIINumber] = useState('');
  const PIIInvalidMsg = 'Invalid PII number. Please re-enter.';
  let  showModal   =  useSelector(state=>state.ingestion.showModal);
  let ModaltotalCnt = useSelector(state=>state.ingestion.ModaltotalCnt);
  let ModalArtcleExists = useSelector(state=>state.ingestion.ModalArtcleExists);
  let isStatusInprogess = useSelector(state=>state.ingestion.isStatusInprogess);
  let alertMsg = useSelector(state=>state.ingestion.alertMsg);
  let alertType = useSelector(state=>state.ingestion.alertType);
  let PIIData =[];  
  
  const handleInputChange=(event)=>{
    dispatch(setError(''));  
    const {value} = event.target;
    setPIINumber(value);
  }
 
  const getIngestionList=()=>{
    dispatch(setAlertDATA(''));
    let PII = PIINumber.replace(/[^a-zA-Z0-9]/g, "");
    let error;
    error = validateInputPII(PII);
    if(error === true)
      dispatch(setError(PIIInvalidMsg));
    else
      dispatch(getIngestionData(PII));

  }

  const submitPII = async()=>{
    dispatch(setAlertDATA(''));
    
    let PII = PIINumber.replace(/[^a-zA-Z0-9]/g, "");
    let error;
    
    error = validateInputPII(PII);
    if(error === true)
      dispatch(setError(PIIInvalidMsg));
    else
    {
      PIIData= await dispatch(getIngestionData(PII,true));
      if(PIIData.totalCnt === 0 && PIIData.metaDataExists===false){
        showModal = false;
        replaceArticle();
      }
      else{
        showModal = true;  
      }  
      dispatch(setModalData(showModal,PIIData.totalCnt,PIIData.metaDataExists,PIIData.isStatusInprogess))
      
    }
  }

  const validateInputPII = (PII)=>{
    let error =false;
    
    if(PII.length !== 17)
      error =true;
    else{
      let PIIList = PII.split('');
      if(!PIIList[0].match(/[a-z]/i))
        error =true;
      else{
        PIIList.splice(0,1);
        PIIList.forEach((val)=>{
          if(val !=='X' && !Number.isInteger(parseInt(val)))
            error =true;
        })
      }
    }
    return error;
  }

  const handleInputKeyUp=(ev)=>{
    if(ev.key==='Enter')
      getIngestionList();
  }
  
  const replaceArticle = async()=>{
    dispatch(setModalData(false));
    await dispatch(submitIngestion(PIINumber.replace(/[^a-zA-Z0-9]/g, "")));
    dispatch(getIngestionData(PIINumber.replace(/[^a-zA-Z0-9]/g, "")));
  }

  const viewStatus=()=>{
    dispatch(setModalData(false));
    dispatch(getIngestionData(PIINumber.replace(/[^a-zA-Z0-9]/g, "")));
  }

  const onAlertClose=()=>{
    dispatch(setAlertDATA(''));
  }
  

  return (
    
    <>
      <div className="row">
          <div className="col-9" >
          {alertMsg && <Alert variant={alertType} alertMsg={alertMsg} onAlertClose={onAlertClose}/>}
          </div>
      </div>
      <div className="row">
          <div className="col-9">
              <input
                  type="text"
                  name="PII"
                  className={`form-control ${PIIError && 'is-invalid'}`}
                  aria-label="PII"
                  spellCheck="false"
                  placeholder="Enter PII eg. S0123456789012"
                  autoComplete="off"
                  value={PIINumber}
                  onChange={handleInputChange}
                  onKeyUp= {handleInputKeyUp}
              />
              {PIIError && <small id="passwordHelp" className="text-danger">
                {PIIError}
              </small>
              }  
          </div>
          <div className="col-3" align="center">
              <button
                      type="button"
                      aria-label="Search PII"
                      className="searchBtn btn"
                      onClick={getIngestionList}
                  >
                  Search
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                      type="button"
                      aria-label="Submit PII"
                      className="btn   submitBtn"
                      onClick ={submitPII}
                  >
                Ingest Article
              </button>
          </div> 
      </div>
      
      {showModal===true && <Modal replaceArticle={replaceArticle} viewStatus={viewStatus} totalCnt={ModaltotalCnt}  articleExists={ModalArtcleExists} isStatusInprogess={isStatusInprogess}/>}  
      
    </>
  );
};
