import React from 'react';
import {useDispatch,useSelector} from 'react-redux';

import {downloadCSV} from './actions';

export default ()=>{

    const dispatch = useDispatch();
    const {total,imported,duplicate,alreadyExist,invalid} = useSelector(state=>state.pinInfo.summaryInfo);

    const downloadCSVFile = ()=>{
        dispatch(downloadCSV());
    }

    return(
    <div className={`import-summary-container ${invalid>0 ? 'bg-error' : 'bg-valid'}`}>
            <div className="import-summary">
                
                {invalid>0 &&
                <p>    
                    <b>{invalid} out of {total}</b> records were invalid. Uploaded CSV file has been updated with identified errors.<br/>
                    1. <span  className="anchor-link" onClick={downloadCSVFile}>(<u>Download PIN_Codes_File.csv</u>)</span> and make correction.<br/>
                    2. Delete <b>Status</b> and <b>Message</b> columns and re-upload CSV file to import PIN Codes.
                </p>}
                
                {imported === total && <p>
                    <b>All</b> the PIN codes were imported successfully. No further action needed.     
                </p>}

                {invalid ===0 && (duplicate>0 || alreadyExist>0) && <p>
                    <b>{imported} out of {total}</b> PIN codes were imported successfully. Please verify the uploaded CSV file and try again to reimport PINs if needed.     
                </p>}
            </div>
            <div className="summary-div">
                <div>
                    <span className="summaryCount">{total}</span>
                    <span className="summaryReport">total records processed</span>
                 </div>

                 <div>
                    <span className="summaryCount">{imported}</span> 
                    <span className="summaryReport">PIN codes successfully imported</span>
                 </div>

                 {alreadyExist>0 && <div>
                    <span className="summaryCount">{alreadyExist}</span>
                    <span className="summaryReport">PIN codes already exist in the system and not imported</span>
                 </div>}

                 {duplicate>0 && <div>
                    <span className="summaryCount">{duplicate}</span>
                    <span className="summaryReport">duplicate records found in uploaded CSV and not imported</span>
                 </div> } 
                 
                 {invalid>0 &&<div>
                    <span className="summaryCount">{invalid}</span>
                    <span className="summaryReport">invalid records and not imported</span>
                 </div> } 
            </div>
    </div>);
}