import {SET_AC_INGEST_FILE,SET_ERROR,SET_MODAL_DATA,SET_UPLOAD_STATUS,SET_AUDIENCE,SET_FILE_ID,SET_FIELD_ERROR, SET_SUMMARY_INFO,SET_SHOW_SUMMARY,SET_RESOURCE_ID,CLEAR_STATE} from '../actions';

export const initialState={
    acIngestFile:[],
    modalInfo:{
        showModal :false
    },
    isUploaded:false,
    audience:'',
    resourceId:'',
    fieldError:[],
    summaryInfo:{
        error:{},
        ancillaryContentAssets:[]
    }
}

export default (state={...initialState},action={})=>{
    switch(action.type){
        
        case SET_AC_INGEST_FILE :
            return {
                ...state,
                acIngestFile: action.acIngestFile,
            }; 
        
        case SET_ERROR :
            return {
                ...state,
                error: action.error,
                errorVariant:action.errorVariant
        }; 

        case SET_MODAL_DATA :
            return {
                ...state,
                modalInfo:{
                    ...state.modalInfo,
                    showModal: action.showModal,
                    ImportStatus:action.ImportStatus,
                    importType:action.importType
                }  
            };
            
        case SET_UPLOAD_STATUS:
            return{
                ...state,
                isUploaded:action.uploadStatus
            }

        case SET_AUDIENCE:
            return{
                ...state,
                audience:action.audience
            } 
        
        case SET_FILE_ID:
            return{
                ...state,
                fileId:action.fileId
            }

        case SET_FIELD_ERROR:
            return{
                ...state,
                fieldError:{
                    ...state.fieldError,
                    [action.fieldnName]:action.errorMsg,
                }
            }

        case SET_SUMMARY_INFO:
            return{
                ...state,
                summaryInfo:action.summaryInfo
            }

        case  SET_SHOW_SUMMARY:
            return{
                ...state,
                isShowSummary:action.isShowSummary
            }
        
        case  SET_RESOURCE_ID :
            return{
                ...state,
                resourceId : action.resourceId
            }
        
        case CLEAR_STATE:
            return  initialState;      
                
        default:
            return state; 
    }
}