import React from "react";
import { useSelector } from 'react-redux';
import './index.scss';
import MenuList from './MenuList';

export default () => {
  const items = useSelector(state => state.menu.items);
  const error = useSelector(state => state.menu.error);
  const isLoading = useSelector(state => state.menu.isLoading);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return isAuthenticated ? (
    <nav className="container znr-top-menu mb-5 mt-2">
      <MenuList items={items} isLoading={isLoading} error={error}></MenuList>
    </nav>
  ) : null;
};
