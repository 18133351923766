import React from "react";
import { useSelector } from "react-redux";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Loading from "../Utils/Loading";

export default () => {
    
    const entitlements = useSelector(state=>state.user.entitlements);
    const isLoading  = useSelector(state=>state.user.isLoading);
    const rows = [];



    entitlements.forEach(function (entitlement,key) {
    rows.push(
      <tr key={entitlement.id}>
        <td><input type="checkbox" /></td>
        <td className="isbn">{entitlement.contentId}</td>
        <td className="publication">
            {entitlement.publicationTitle.length>55 ? <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={
              <Tooltip id="button-tooltip"  >
              {entitlement.publicationTitle}
            </Tooltip>
            }>
                <span className="pointer-show">{entitlement.publicationTitle.substring(0,54)}<b className="dot">..</b></span>
    </OverlayTrigger>:entitlement.publicationTitle}
          {/*entitlement.publicationTitle.length>55 ? <span style={{cursor:'pointer'}}  title={entitlement.publicationTitle} data-toggle="popover" data-placement="top" data-content="Content">{entitlement.publicationTitle.substring(0,54)}<b className="dot">..</b></span>:entitlement.publicationTitle*/}
        </td>
        <td className="redeem-date">{entitlement.redeemDate}</td>
        <td className="pin">{entitlement.type}</td>
        <td className="entitlement-active">
            <div className={entitlement.status.toUpperCase()==='ACTIVE'?'entitlement-active':'entitlement-inactive'}>{entitlement.status}</div>    
        </td>
      </tr>
    );
    
  });

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="activate-deactivate">
            <span className="activate-btn">Activate</span>
            <span className="deactivate-btn">Deactivate</span>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: "-25px" }}>
        <div className="col-12 pt-3">
          {isLoading === true && <div className="text-center loader">
            <Loading/>
        </div>}
          <table className="sticky top table table-sm mt-2 border-bottom border-top entitlement-tbl">
            <thead>
              <tr>
                <th className="email">
                  <input type="checkbox" />
                </th>
                <th className="isbn">ISBN</th>
                <th className="publication">Publication</th>
                <th className="redeem-date">Redeem Date</th>
                <th className="pin">PIN</th>
                <th className="entitlement-active">Status</th>
              </tr>
            </thead>
            <tbody>
              {isLoading !== true && rows}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
