import React from "react";
import { Redirect } from "react-router-dom";
import Login from '../../modules/Login';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const LoginRoute = ({ location }) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const { from } = location.state || { from: { pathname: "/" } };
  return isAuthenticated === true ? (<Redirect to={from} />) : (<Login></Login>);
};

LoginRoute.propTypes = {
  location: PropTypes.object
};

export default LoginRoute;
