import React,{useState} from 'react';
import Alert from 'react-bootstrap/Alert';

function AlertDismissible({variant,alertMsg,onAlertClose}) {
    const [show, setShow] = useState(true);
  
    const onClose = ()=>{
        setShow(false);
        onAlertClose();
    }

    if (show) {
      return (
        <Alert variant={variant} onClose={onClose} dismissible >
            {alertMsg}
        </Alert>
      );
    }
    return('');
  }
  
  export default AlertDismissible;