import React from 'react';
import {useDispatch} from 'react-redux';

import {setModalData,setShowSummary} from './actions';

export default ({ImportStatus,importType, fileName})=>{
    const dispatch = useDispatch();
    
    let modalTitle,modalMsg,showSummaryBtn,closeButton,showOkButton;
    if(importType === 'upload'){
        
        if(ImportStatus === 'inprogress'){
            modalTitle = `Uploading ${fileName}`;
            modalMsg='Please wait while ZIP file is being uploaded. This might take few minutes.';
        }else if(ImportStatus === 'done'){
            modalTitle = 'Upload Completed';
            modalMsg='ZIP file has been uploaded. Please proceed with Ingestion by clicking on Ingest Content.';
            showOkButton = true;
            closeButton =true;
        }
    }else if(importType === 'ingest'){
        
        if(ImportStatus === 'inprogress'){
            modalTitle = `Ingesting Ancillary Content`;
            modalMsg='Please wait while the uploaded ZIP file is being ingested.';
        }else if(ImportStatus === 'done'){
            modalTitle = 'Ingestion Completed';
            modalMsg='Ancillary content has been successfully ingested, it is now available for products to consume.';
            showOkButton = true;
            closeButton =true;
        }else if(ImportStatus==='processing_error'){
            modalTitle = 'Processing Error';
            modalMsg='There was Processing Error , please contact support team.';
            showOkButton = true;
            closeButton =true;
        }else if(ImportStatus==='invalid_input'){
            modalTitle = 'Ingestion Failed';
            modalMsg='There are errors in the uploaded file. Please fix the errors and try again.';
            showSummaryBtn = true;
            closeButton =true;
        }
    }
 
    const closeModal = ()=>{
        dispatch(setModalData(false));
    }

    const onOkClick=()=>{
        dispatch(setModalData(false));
    }

    const viewSummary=()=>{
        dispatch(setModalData(false));
        dispatch(setShowSummary(true));
    }

    return(
        <div id="myModal" className="modal1">
            <div className="modal-content1" >
                <div className="modal-body1">
                    {closeButton ===true &&<span className="close" onClick={closeModal}>&times;</span>}
                    <p className="modal-title1">
                        {ImportStatus==='done' && <img src="/assets/images/success-tick.svg" alt="success"  />}
                       {ImportStatus==='inprogress' && <img src="/assets/images/spin-loader.svg" alt="loader" className="rotate"/>}
                       {ImportStatus==='processing_error' && <img src="/assets/images/error-cross.svg" alt="error"/>}
                       {ImportStatus==='invalid_input' && <img src="/assets/images/error.svg" alt="error"/>}
                        &nbsp;&nbsp;{modalTitle}
                    </p>
                    <p className="popup-msg">{modalMsg}</p>
                    <div className="modal-btn">
                        {showOkButton &&<button
                                type="button"
                                aria-label="Ok Button"
                                className="btn replaceBtn"
                                onClick={onOkClick}
                            >
                                Ok
                            </button>}
                        
                        {showSummaryBtn &&<button
                            type="button"
                            aria-label="Show Summary"
                            className="btn replaceBtn"
                            onClick={viewSummary}
                           >
                            View Details
                        </button>}
                    </div>
                </div>
            </div>
      </div>
    );
}