import React from 'react';
import {useSelector} from 'react-redux';


export default ()=>{

    const {ancillaryContentAssets,error} = useSelector(state=>state.acIngest.summaryInfo);
    
    let rows=[];
    ancillaryContentAssets && ancillaryContentAssets.forEach((value,index) => {
        
        const {fileName,error,status} = value;
        status==='INVALID' && rows.push(
            <div key={fileName}>
                <span className="summaryReport">at row {index+1} : {fileName} : {error.message}</span>
            </div>
        );
    });

    return(
    <div className="import-summary-container bg-error">
            {rows.length>0 ?
                <>
                    <div className="import-summary">
                        <p><b>Error in manifest.csv</b></p>
                    </div>
                    <div className="summary-div">
                        {rows}
                    </div>
                </>    
            :
                <>
                    <div className="import-summary">
                        <p><b>Error Details</b></p>
                    </div>
                    {error &&
                        <div className="summary-div">
                            {error.message}
                        </div>
                    }
                </>
            }
    </div>);
}