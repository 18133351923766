import React from "react";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ArticleIngestion from '../../modules/ArticleIngestion';
import Unavailable from '../../modules/SystemStatus/Unavailable';
import { clearState} from '../../modules/ArticleIngestion/actions';


export default ({location}) => {

  const dispatch = useDispatch();
  useEffect(() => {
    return function cleanUpState(){
      dispatch(clearState());
    };
  });

  return <>
    <h2 className="page-title">Article Ingestion</h2>
    {process.env.REACT_APP_SWITCH_OFF_ARTICLE_INGESTION === 'true' ? <Unavailable path={location.pathname} /> : <ArticleIngestion />}
  </>;
};
