import React,{useState} from "react";
import {useSelector,useDispatch} from 'react-redux';
import moment from 'moment';

import Loading from '../Utils/Loading';
import {getSortData} from './actions'

export default () => {
  
  const dispatch = useDispatch();
  
  const ingestions = useSelector(state=>state.ingestion.ingestions);
  const totalCount = useSelector(state=>state.ingestion.totalCount);
  const isLoading  = useSelector(state=>state.ingestion.isLoading);
  const PII        = useSelector(state=>state.ingestion.PII);
  const articleExists  = useSelector(state=>state.ingestion.articleExists);
  const rows=[];
  const [sortType, setSortType] = useState({
    submittedOn : false
  });
  
  ingestions.forEach(function (ingestion) {
    const articles = ingestion.articles.filter((article)=>{
      return article.pii === PII;
    });
    const article = articles[0];
    
    rows.push(
      <tr key={ingestion.id}>
        <td className="indgestion-id td-unbreak">{ingestion.id}</td>
        <td className="ingestion-status">{article.status}</td>
        <td className="error-code">{article.error && article.error.code}</td>
        <td className="error-message">{article.error && article.error.message}</td>
        <td className="submitted-by td-unbreak">{ingestion.submittedBy && ingestion.submittedBy.split('|')[2]}</td>
        <td className="submitted-on td-unbreak">{ingestion.submittedOn && moment(ingestion.submittedOn).format('DD MMM YYYY')}</td>
        {/*<td className="recommended-actions"></td>*/}
      </tr>
    );
  });


  const sortData = (columnName)=>{
    const sortTypeNew = {...sortType};
    sortTypeNew.submittedOn = !sortType.submittedOn;
    setSortType(sortTypeNew);
    dispatch(getSortData(columnName,sortTypeNew.submittedOn));
  }

  if(totalCount === 0 && PII)
  {
    rows.push(
      <tr key='no-history'>
        <td className="no-indgestion" colSpan="7">Ingestion history not found</td>
      </tr>
    );
  }

  return (  
    <>
      <div className="col-12 pt-3 PII-data">
        {isLoading === true && <div className="text-center loader"><Loading/></div>}
        <div className="pii-number">PII : {PII ? PII : 'NA'} 
          {(articleExists ===true &&<><img  src="/assets/images/tick.svg" alt="tick" className="aticle-exists" data-toggle="popover" title="Article exist in system, it is available for products to consume." /><span className="article-present-font">Article exist in system</span></>) || (articleExists ===false && <> <img  src="/assets/images/error.svg" alt="error" className="aticle-no-exists" data-toggle="popover" title="Article does not exist in system, ingest to add it into system"/><span className="article-absent-font">Article does not exist in system</span></>)}
        </div>
        <br/>
        <div style={{fontFamily:"'Nexus Sans Pro', sans-serif",fontSize:'14px',color:"#333333"}}>Ingestion History</div>
        
        <table className="sticky top table table-sm mt-3 ingestion-tbl">
          <thead>
            <tr>
              <th className="indgestion-id">Ingestion ID</th>
              <th className="ingestion-status">Ingestion Status</th>
              <th className="error-code">Error Code</th>
              <th className="error-message">Error Message</th>
              <th className="submitted-by">Submitted By</th>
              <th className="submitted-on pointer" onClick={()=>sortData('submittedOn')}>Submitted On {sortType.submittedOn === true?<img  src="/assets/images/arrow-up.svg" alt="ascending" />:<img  src="/assets/images/arrow-down.svg" alt="descending" />}</th>
              {/*<th className="recommended-actions">Recommended Actions</th>*/}
            </tr>
          </thead>
          <tbody>
          {isLoading !== true && rows}
          </tbody>
        </table>
      </div>
    </>
  );
};
