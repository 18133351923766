import React, {useEffect } from "react";
import PropTypes from 'prop-types';
import './index.scss';

const Modal = ({title, children, show, setShow, closebtntxt = 'Close'}) => {

  useEffect(() => {
    if(show === true) {
      document.body.classList.add('modal-open');
      document.body.setAttribute('style', 'padding-right: 15px');
    } else {
      document.body.classList.remove('modal-open');
      document.body.removeAttribute('style');
    }
  });

  return (
    <>
      <div className={(show ? 'show ' : '') + 'modal fade'} style={show ? {'display': 'block'} : {'display':'none'}} tabIndex="-1" role="dialog" aria-modal="true" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title h4">{title}</h5>
              <button onClick={() => setShow(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-footer">
              <button type="button" onClick={() => setShow(false)} className="btn btn-primary close-btn">{closebtntxt}</button>
            </div>
          </div>
        </div>
      </div>
      { show && <div onClick={() => setShow(false)} className="show modal-backdrop fade"></div> }
    </>
  );
};


Modal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  children: PropTypes.node,
  closebtntxt: PropTypes.string
};

export default Modal;
