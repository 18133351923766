import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const SecureRoute = ({component: Component, ...rest}) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  return (<Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />);
};

SecureRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.object.isRequired
  ])
};


export default SecureRoute;
