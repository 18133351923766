import React from "react";
import { useSelector } from 'react-redux';
import Loading from '../Utils/Loading';
import Error from '../Utils/Error';
import './index.scss';

export default () => {
  const info = useSelector(state => state.scim.info);
  const isLoading = useSelector(state => state.scim.isLoading);
  const error = useSelector(state => state.scim.error);


  return (
    <>
      {info ?
        <table className="table table-sm">
          <tbody>
            <tr>
              <th>Display Name</th>
              <td>{info.displayName}</td>
            </tr>
            <tr>
              <th>Active</th>
              <td>{info.active ? 'Yes' : 'No'}</td>
            </tr>
            <tr>
              <th>First Name</th>
              <td>{info.name ? info.name.givenName : ''}</td>
            </tr>
            <tr>
              <th>Family Name</th>
              <td>{info.name ? info.name.familyName : ''}</td>
            </tr>
          </tbody>
        </table> : null
      }
      {isLoading ?
        <div className="pt-5 d-flex justify-content-center">
          <div className="scim-loading">
            <Loading />
          </div>
        </div>
        : null
      }

      {error ?
        <div className="pt-5 d-flex justify-content-center">
          <Error error={error}/>
        </div>
        : null
      }

    </>
  );
};
