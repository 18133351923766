import React from "react";
import { useDispatch } from 'react-redux';
import { PIN_STATUS_ACCEPTED, PIN_STATUS_REJECTED, putStatus } from './actions';

export default () => {
  const dispatch = useDispatch();
  const handleSubmit = (status) => {
    dispatch(putStatus(status));
  };

  return (
    <>
      <button
        className="btn btn-primary reject-btn ml-3 float-right"
        onClick={() => handleSubmit(PIN_STATUS_REJECTED)}>
        Reject
      </button>
      <button
        className="btn btn-primary approve-btn float-right"
        onClick={() => handleSubmit(PIN_STATUS_ACCEPTED)}>
        Approve
      </button>
    </>
  );
};
