export const SET_MENU = 'SET_MENU';
export const SET_MENU_ERROR = 'SET_MENU_ERROR';
export const SET_MENU_LOADING = 'SET_MENU_LOADING';

export const getMenu = () => {
  return async (dispatch) => {
    dispatch(setLoading());
    const response = await fetch('/api/menu.json');

    if(response.ok) {
      let data = await response.json();

      if(data.error === undefined) {
        dispatch(setMenu(data));
      } else {
        dispatch(setError(data.error));
      }
    } else {
      dispatch(setError('something went wrong please check page later'));
    }

  };
};

export const setMenu = (items) => {
  return { type: SET_MENU, items: items };
};

export const setError = (error) => {
  return { type: SET_MENU_ERROR, error: error };
};

export const setLoading = () => {
  return { type: SET_MENU_LOADING };
};
