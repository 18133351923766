import AuthService from '../../../services/AuthService';
export const SET_AUTH_LOGIN = 'SET_AUTH_LOGIN';
export const SET_AUTH_LOGOUT = 'SET_AUTH_LOGOUT';
export const SET_AUTH_PROCESSING = 'SET_AUTH_PROCESSING';

export const setAuth = (accessToken, userId) => {
  return {
    type: SET_AUTH_LOGIN,
    accessToken,
    userId
  };
};
export const loginInProcessing = () => {
  return {type: SET_AUTH_PROCESSING};
};

export const clearAuth = () => {
  return {type: SET_AUTH_LOGOUT};
};

export const login = () => {
  return async (dispatch) => {
    await AuthService.initLogin();
  };
};

export const logout = () => {
  return async (dispatch) => {
    await AuthService.logout();
  };
};
