import React from "react";

import Filter from './Filter';
import PIIData from './PIIData';
import './index.scss';

export default () => {

  return (
    <>
        <Filter/>
        <br/>
        <div className="row">
            <PIIData/>
        </div>   
    </>
  );
};
