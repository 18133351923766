import React,{useEffect} from "react";
import {useDispatch} from 'react-redux';
//import {useParams } from 'react-router-dom'


import AddPermission from '../../modules/AddPermission';
import {getEntitlements,getUserInfo} from '../../modules/UserManagement/actions'


export default () => {
    //const {userId} = useParams();
    const userId=decodeURIComponent(window.location.href).split('/').reverse()[0];
    const dispatch  =  useDispatch() ;
    useEffect(()=>{
        dispatch(getUserInfo(userId));
        dispatch(getEntitlements(userId));
    })
    
    return <>
        <AddPermission></AddPermission>
    </>;
};
