import fetchApi from '../../../services/ApiService';

//basic user actions
export const SET_INGESTION_LIST = 'SET_INGESTION_LIST';
export const SET_PIN_LOADING = 'SET_INGESTION_PIN_LOADING';
export const SET_PIN_ERROR = 'SET_INGESTION_ERROR';
export const API_ERROR = 'something went wrong please check page again later';
export const SET_PII = 'SET_PII';
export const SET_ARTICLE_EXISTS = 'SET_ARTICLE_EXISTS';
export const SET_SORT_DATA = 'SET_SORT_DATA';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_ALERT_DATA = "SET_ALERT_DATA";
export const CLEAR_STATE = "CREAR_INGESTION_STATE";

export const INGESTION_SUCCESS_MSG = 'Ingestion request submitted successfully';
export const INGESTION_ERROR_MSG = 'Ingestion request failed';
export const UNAUTHORIZED_ERROR = 'You are not authorized to perform this operation';

export const getIngestionData = (PIINumber, submitAction)=>{
      
      return async(dispatch, getState)=>{
        
        const token = getState().auth.accessToken;
        
        if(token){
            dispatch(setLoading());
            
            const url = new URL(`${process.env.REACT_APP_CONTENT_BASE_URL}/articles-ingestions`);
            url.searchParams.append('pii', PIINumber);
            url.searchParams.append('limit', '50');
            url.searchParams.append('offset', '0');

            let response;
            try {
              response = await fetchApi(url, {
                method: 'GET',
                headers: {
                  'Authorization': 'Bearer '+token,
                },
              });
            } catch(e) {
      
            };
            //let articleExists = false;
            let proceedExists = false;
            let reproceedExists = false;
            let totalCnt = 0;
            
            if(response && response.ok) {
                const data = await response.json();
                if(data.total>0){
                  totalCnt = data.total;
                  data.ingestions.forEach((ingestion)=>{
                    ingestion.articles.forEach((article)=>{
                      if(article.pii === PIINumber)
                      {
                        if(article.status.toUpperCase() === 'PROCESSED'){
                          proceedExists = true;
                        }else if(article.status.toUpperCase() === 'RE_PROCESSED'){
                          reproceedExists =true;
                        }
                      }
                    })
                  })
                  
                  /*if(proceedExists === true)
                    articleExists =true;
                  dispatch(setIngestionList(data.ingestions, data.total,PIINumber,articleExists)); */ 
                  }
                
                
                
                const metaDataExists = await dispatch(checkArticleExists(PIINumber));
                if(submitAction===true){
                  
                  let isStatusInprogess = false;
                  if(totalCnt>0){
                    data.ingestions.sort((a,b)=>{
                      return b.submittedOn>=a.submittedOn?1:-1;   
                    })
                    const articles = data.ingestions[0].articles.filter((article)=>{
                      return article.pii === PIINumber;
                    });
                    const article = articles[0];
                    if(article.status.toUpperCase() === 'IN_PROGRESS')
                      isStatusInprogess = true;
                  }
                  
                  return {
                    totalCnt,
                    metaDataExists,
                    isStatusInprogess
                  }
                }
                
                dispatch(setIngestionList(data.ingestions, totalCnt,PIINumber));  
                dispatch(setArticleExists(metaDataExists));
                
                if((proceedExists===true || reproceedExists===true )&& metaDataExists === false )
                  dispatch(setError(API_ERROR))
                
              
            }else{

              let error;
              const statusCode = response && response.status;
              if(statusCode === 403){
                error = UNAUTHORIZED_ERROR;
                dispatch(setAlertDATA(error));
              }else if(statusCode===400){
                error= 'Invalid PII number. Please re-enter';
                dispatch(setError(error));
              }else {
                error = API_ERROR;
                dispatch(setAlertDATA(error));
              }

              if(submitAction===true)
                return{
                  totalCnt:0,
                  metaDataExists:false,
                  isStatusInprogess:false
                }
            } 
        }
    }
}

export const checkArticleExists=(PII)=>{
  return async(dispatch)=>{
    
    const url = new URL(`${process.env.REACT_APP_CONTENT_BASE_URL}/articles-metadata/${PII}`);

    let articleExists = false;
    let response;
    try {
      response = await fetchApi(url,{
        method:'GET'
      })
    }catch(e){

    }
    
    if(response && response.ok){
      await response.json();
      articleExists =true;
    }else{
      
      let error;
      const statusCode = response && response.status;
      if(statusCode === 403){
        error = UNAUTHORIZED_ERROR;
        dispatch(setAlertDATA(error));
      }
    }
    //dispatch(setArticleExists(articleExists));
    return articleExists;
  }
}

export const submitIngestion = (PII)=>{
  return async(dispatch, getState)=>{
    
    let token = getState().auth.accessToken;
    if(token){
      dispatch(setLoading());
      const url = new URL(`${process.env.REACT_APP_CONTENT_BASE_URL}/articles-ingestions`);
      
      let response;
      let alertMsg,alertType;
      let PIIList = {
        articles:[
          {
            pii:PII
          }
        ]
      }
      
      try{
        response = await fetchApi(url,{
          method: 'POST',
          body:JSON.stringify(PIIList),
          headers: {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/json'
          },
        })
      }catch(e){

      }

      if(response && response.ok){
        alertMsg = INGESTION_SUCCESS_MSG;
        alertType ='success';
      }
      else{

        alertType ='danger';
        const statusCode = response && response.status;
        if(statusCode === 403)
          alertMsg = UNAUTHORIZED_ERROR;
        else 
          alertMsg = INGESTION_ERROR_MSG;
        
        
      }
      dispatch(setAlertDATA(alertMsg,alertType));
    }
  } 
}


export const setAlertDATA = (alertMsg,alertType='danger')=>{
  return {type: SET_ALERT_DATA,alertMsg,alertType}
}

export const getSortData =(columnName,sortType)=>{
  return async(dispatch, getState)=>{
    const ingestions = getState().ingestion.ingestions;
    dispatch(setSortData(columnName,sortType,ingestions));
  }
}

export const setModalData=(showModal,ModaltotalCnt,ModalArtcleExists,isStatusInprogess)=>{
  return{type : SET_MODAL_DATA, showModal,ModaltotalCnt,ModalArtcleExists,isStatusInprogess}
}


export const setSortData =(columnName,sortType,ingestions)=>{
  return {type: SET_SORT_DATA,columnName,sortType,ingestions}
}

export const setArticleExists=(articleExists)=>{
  return {type:SET_ARTICLE_EXISTS,articleExists}
}

export const setError = (error) => {
  return { type: SET_PIN_ERROR, error };
};

export const setIngestionList = (ingestions, totalCount,PII) => {
    return { type: SET_INGESTION_LIST, ingestions, totalCount,PII};
};

export const setLoading = ()=>{
    return{type:SET_PIN_LOADING}
}

export const setPII =(PII)=>{
  return{type:SET_PII,PII}
}

export const clearState =()=>{
  return{type:CLEAR_STATE}
}