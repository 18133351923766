import { SET_AUTH_LOGIN, SET_AUTH_LOGOUT, SET_AUTH_PROCESSING } from '../actions';

const initialState = {
  isAuthenticated: false,
  isProcessing: false,
  accessToken: null,
  userId: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
  case SET_AUTH_LOGIN:
    return {
      isAuthenticated: true,
      isProcessing: false,
      accessToken: action.accessToken,
      userId: action.userId
    };
  case SET_AUTH_LOGOUT:
    return {
      isAuthenticated: false,
      isProcessing: false,
      accessToken: null,
      userId: null
    };
  case SET_AUTH_PROCESSING:
    return {
      isAuthenticated: false,
      isProcessing: true,
      accessToken: null,
      userId: null
    };
  default:
    return state;
  }
};
