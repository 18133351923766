import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { login } from './actions';
import './index.scss';

const Login = () => {
  const dispatch = useDispatch();
  const isProcessing = useSelector(state => state.auth.isProcessing);
  return (
    <div className="znr-login position-relative">
      <div className="znr-login-background position-absolute">
      </div>
      <div className="znr-login-content position-absolute">
        <div className="card">
          <div className="card-header bg-white">
            <img className="auth0-logo d-flex justify-content-center" src="/assets/images/auth0.png" alt="auth0"/>
            <h3>Auth0</h3>
          </div>
          <div className="card-body d-flex justify-content-center">
            {isProcessing ?
              (<div className="spinner-border text-primary loading" role="status">
                <span className="sr-only">Loading...</span>
              </div>)
              :
              (<button id="login-btn" className="btn btn-primary login-btn" onClick={() => dispatch(login())}>Log in</button>)
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
