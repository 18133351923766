import { SET_MENU, SET_MENU_ERROR, SET_MENU_LOADING } from '../actions';
import menuMap from './buildMenuMapper.js';

export const initialState = {
  items: {},
  isLoading: false,
  error: null,
  switches: new Map(), //switches is a seprate field here as it avoids looping through menu items each time we need to check for switches
  showStatusWarning: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
  case SET_MENU:
    const switches = new Map();
    let info, upCount, mainInfo,totalCount, showStatusWarning = false;
    Object.values(action.items).forEach(function (item) {
      mainInfo = menuMap(item.id) ? menuMap(item.id) : [];
      item.htmlId = 'id' + item.id.replace('/', '-');
      totalCount = 1;
      upCount = 0;
      if(item.children) {
        Object.values(item.children).forEach(function (child) {
          totalCount++;
          info = menuMap(child.id);
          if(info) {
            switches.set(child.id, {...info});
            showStatusWarning = true;
          } else {
            upCount++;
          }
          if(child.children) {
            Object.values(child.children).forEach(function (gchild) { //we are digging three level only for the switches
              totalCount++;
              info = menuMap(gchild.id);
              if(info) {
                switches.set(gchild.id, {...info});
                showStatusWarning = true;
              } else {
                upCount++;
              }
            });
          }
        });
      }
      if(mainInfo.length === 0) {
        upCount++;
      } else {
        showStatusWarning = true;
      }

      mainInfo['upCount'] = upCount;
      mainInfo['totalCount'] = totalCount;
      switches.set(item.id, {...mainInfo});
    });

    return {
      items: action.items,
      isLoading: false,
      error: null,
      switches,
      showStatusWarning
    };
  case SET_MENU_ERROR:
    return {
      items: {},
      isLoading: false,
      error: action.error,
      switches: new Map(),
      showStatusWarning: false
    };
  case SET_MENU_LOADING:
    return {
      items: {},
      isLoading: true,
      error: null,
      switches: new Map(),
      showStatusWarning: false
    };
  default:
    return state;
  }
};
