import React from "react";
import PropTypes from 'prop-types';

const Message = ({error}) => {

  return (
    <div className="alert alert-danger" role="alert">
      {error}
    </div>
  );
};


Message.propTypes = {
  error: PropTypes.string.isRequired
};

export default Message;
