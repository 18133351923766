import React, {useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import {addSelectedPin, removeSelectedPin, getPinList} from './actions';
import UserInfoModal from './UserInfoModal';
import Loading from '../Utils/Loading';

export default () => {

  const items = useSelector(state => state.pin.items);
  const isLoading = useSelector(state => state.pin.isLoading);
  const dispatch = useDispatch();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const rows = [];

  const handleClick = (event) => {
    if(event.target.checked) {
      dispatch(addSelectedPin(event.target.value));
    } else {
      dispatch(removeSelectedPin(event.target.value));
    }
    event.stopPropagation();
  };

  const handleShowUserDetails = (requesterId) => {
    setShowUserInfo(true);
    dispatch(getPinList(null, requesterId));
  };

  Object.values(items).forEach(function (item, key) {
    rows.push(
      <tr className="data-row" key={item.id} id={item.id} onClick={() => handleShowUserDetails(item.requesterId)}>
        <td className="chk">
          <input className="form-check-input" type="checkbox" value={item.id} aria-label="Select PIN" onClick={handleClick}/>
        </td>
        <td className="email">{item.requesterEmailAddress}</td>
        <td className="given-name">{item.requesterGivenName}</td>
        <td className="family-name">{item.requesterFamilyName}</td>
        <td className="site">{item.productId}</td>
        <td className="publication">{item.publicationTitle}</td>
        <td className="isbn">{item.contentId}</td>
        <td className="status">{item.status}</td>
        <td className="created">{item.createdDate}</td>
      </tr>
    );
  });

  return (
    <div className="col-12">
      <table className="sticky top table table-sm pin-list">
        <thead className="table-dark bg-secondary">
          <tr>
            <th className="chk"></th>
            <th className="email">Email</th>
            <th className="given-name">Given Name</th>
            <th className="family-name">Family Name</th>
            <th className="site">Site</th>
            <th className="publication">Publication</th>
            <th className="isbn">ISBN</th>
            <th className="status">Status</th>
            <th className="created">Requested Date</th>
          </tr>
        </thead>
      </table>
      <table className="table table-sm pin-list">
        <tbody>
          {isLoading === true &&
            <tr className="loading">
              <td colSpan="9" className="text-center">
                <Loading />
              </td>
            </tr>
          }
          {isLoading !== true &&
            rows
          }
          {rows.length === 0 && isLoading !== true &&
            <tr className="loading">
              <td colSpan="9" className="text-center no-data">
                no requests found
              </td>
            </tr>
          }
        </tbody>
      </table>
      {isLoading !== true && <UserInfoModal show={showUserInfo}  setShow={setShowUserInfo} /> }
    </div>
  );
};
