import React from "react";
import Modal from '../Modal';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const StatusModal = ({show, setShow, item}) => {
  const switches = useSelector(state => state.menu.switches);
  const rows = [];
  let info;

  info = switches.get(item.id);
  if(info) {
    rows.push(
      <tr key={item.id} id={item.htmlId}>
        <td className="name">{item.name}</td>
        <td className="rationale">{info.rationale}</td>
        <td className="date">{info.date}</td>
      </tr>
    );
  }
  if(item.children) {
    Object.values(item.children).forEach(function (child) {
      info = switches.get(child.id);
      if(info) {
        rows.push(
          <tr key={child.id} id={child.htmlId}>
            <td className="name">{child.name}</td>
            <td className="rationale">{info.rationale}</td>
            <td className="date">{info.date}</td>
          </tr>
        );
      }
      if(child.children) {
        Object.values(child.children).forEach(function (gchild) {
          info = switches.get(gchild.id);
          if(info) {
            rows.push(
              <tr key={gchild.id} id={gchild.htmlId}>
                <td className="name">{gchild.name}</td>
                <td className="rationale">{info.rationale}</td>
                <td className="date">{info.date}</td>
              </tr>
            );
          }
        });
      }
    });
  }


  return (
    <Modal title={item.name ? item.name : ''} show={show} setShow={setShow}>
      <div className="row system-status">
        <div className="col-12">
          <table className="table table-sm system-status__table">
            <thead className="table-dark bg-secondary">
              <tr>
                <th className="name">Name</th>
                <th className="rationale">Rationale</th>
                <th className="date">Date</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};


StatusModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default StatusModal;
