import {SET_INGESTION_LIST, SET_PIN_LOADING, SET_PII,SET_ARTICLE_EXISTS,SET_PIN_ERROR,SET_SORT_DATA, SET_MODAL_DATA,SET_ALERT_DATA,CLEAR_STATE } from '../actions';

export const initialState={
    ingestions:[],
    totalCount:0,
    isLoading:false,
    error:true,
    modalShow:false,
}

export default (state={...initialState},action={})=>{
    switch(action.type){
        case SET_INGESTION_LIST: 
            
            action.ingestions.sort((a,b)=>{
                return b.submittedOn>=a.submittedOn?1:-1;   
            })

            return {
                ...state,
                ingestions:action.ingestions,
                totalCount: action.totalCount,
                isLoading:false,
                PII:action.PII.replace(/[^a-zA-Z0-9]/g, ""),
            }

        case SET_PIN_LOADING :
            return {
                ...state,
                isLoading: true,
            }; 
        
        case SET_PII :
            return{
                ...state,
                PII : action.PII.replace(/[^a-zA-Z0-9]/g, "")
            } 
        
        case SET_ARTICLE_EXISTS:
            return {
                ...state,
                articleExists :action.articleExists
            }    
            
        case SET_PIN_ERROR:
            return{
                ...state,
                PIIError :action.error,
                isLoading: false
            }
        
        case SET_SORT_DATA:
            
            const columnName    = action.columnName;
            const sortType      = action.sortType;
            const ingestions    = action.ingestions;
           
            ingestions.sort((a,b)=>{
                if(sortType === true)
                    return a[columnName]>=b[columnName]?1:-1;
                else  
                    return b[columnName]>=a[columnName]?1:-1;     
            })

            return{
                ...state,
                ingestions :ingestions
            }

        case SET_MODAL_DATA : 
            return {
                ...state,
                showModal:action.showModal,
                ModaltotalCnt:action.ModaltotalCnt,
                ModalArtcleExists:action.ModalArtcleExists,
                isStatusInprogess:action.isStatusInprogess,
                isLoading:false
            } 
            
        case SET_ALERT_DATA:
            return{
                ...state,
                alertMsg:action.alertMsg,
                alertType:action.alertType,
                isLoading:false
            } 
            
        case CLEAR_STATE:
            return  initialState;
            
        default:
            return state; 
    }
}
