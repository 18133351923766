import React, { useState } from "react";
import MenuDetails from './MenuDetails';
import MenuItem from './MenuItem';
import PropTypes from 'prop-types';


const MenuList = ({ items = {}, error = '', isLoading = false}) => {

  const [selectedMenuId, setSelectedMenuId] = useState('');

  const mainLinks = [], detailItems = [];


  Object.values(items).forEach(function (item, key) {
    mainLinks.push(
      <div className="link-item"
        key={item.id}
        onMouseEnter={() => setSelectedMenuId(item.id)}>
        <MenuItem item={item} level={0} />
      </div>
    );
    if(item.children) {
      detailItems.push(<MenuDetails
        className="znr-top-menu__detail"
        setSelectedMenuId={setSelectedMenuId}
        parentId={item.id}
        key={item.id}
        items={item.children}
        showMenu={selectedMenuId === item.id ? true : false}>
      </MenuDetails>);
    }
  });

  return (
    <>
      <input type="hidden" name="menuError" id="menuError" value={error ? error : ''} />
      <div onMouseLeave={() => setSelectedMenuId('')} className="row pb-2 pt-2 znr-top-menu__nav">
        {mainLinks}
      </div>
      <div className="row position-relative znr-top-menu__details">
        {detailItems}
      </div>
    </>
  );
};

MenuList.propTypes = {
  item: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default MenuList;
