import {
  SET_PIN_LIST, SET_PIN_ERROR, SET_PIN_LOADING, ADD_SELECTED_PIN,
  REMOVE_SELECTED_PIN, CLEAR_ALL_SELECTED_PINS, ADD_FILTER,
  REMOVE_FILTER, READ_SETTINGS_FROM_LOCALSTORAGE, FILTER_STORAGE_NAME,
  CLEAR_FILTERS, SET_USER_INFORMATION, SET_USER_ERROR, SET_USER_LOADING
} from '../actions';
import moment from 'moment';

export const pinState = {
  items: {},
  totalCount: 0,
  isLoading: false,
  error: null,
  selectedPinIds: [],
  filters: {
    site: []
  },
  selectedUser: {
    id: null,
    name: null,
    email: null,
    isLoading: false,
    error: null,
    items: {}
  }
};

export const storeFilters = (filters = {}) => {
  Object.keys(filters).forEach((key) => {
    if(filters[key].length) {
      localStorage.setItem(FILTER_STORAGE_NAME + '.' + key, JSON.stringify(filters[key]));
    } else {
      localStorage.removeItem(FILTER_STORAGE_NAME + '.' + key);
    }
  });
};

export const getFiltersFromStore = (filters = {}) => {
  Object.keys(filters).forEach((key) => {
    const filterVal = JSON.parse(localStorage.getItem(FILTER_STORAGE_NAME + '.' + key));
    if(filterVal && filterVal.length) {
      filters[key] = Object.values(filterVal);
    } else {
      filters[key] = [];
    }
  });
  return filters;
};

export default (state = {...pinState}, action = {}) => {
  let newState, filters, items;
  switch (action.type) {

  case SET_PIN_LIST:
    items = [];
    Object.values(action.items).forEach(function (item, key) {
      const fDate = moment(item.createdDate).format('DD-MMM-YYYY');
      item.createdDate = fDate;
      items[item.id] = item;
    });

    return {
      ...state,
      isLoading: false,
      error: null,
      selectedPinIds: [],
      items: items,
      totalCount: action.totalCount
    };

  case SET_USER_INFORMATION:
    items = [];
    Object.values(action.items).forEach(function (item, key) {
      const fDate = moment(item.createdDate).format('DD-MMM-YYYY');
      item.createdDate = fDate;
      items.push(item);
    });
    return {
      ...state,
      selectedUser: {
        id: items[0].requesterId,
        name: items[0].requesterGivenName + ' ' + action.items[0].requesterFamilyName,
        email: items[0].requesterEmailAddress,
        isLoading: false,
        error: null,
        items: items
      }
    };

  case SET_PIN_ERROR:
    return {
      ...state,
      isLoading: false,
      error: action.error
    };

  case SET_USER_ERROR:
    return {
      ...state,
      selectedUser: {
        id: null,
        name: null,
        email: null,
        isLoading: false,
        error: action.error,
        items: {}
      }
    };

  case SET_PIN_LOADING:
    return {
      ...state,
      items: {},
      isLoading: true,
      error: null,
      selectedPinIds: []
    };

  case SET_USER_LOADING:
    return {
      ...state,
      selectedUser: {
        id: null,
        name: null,
        email: null,
        isLoading: true,
        error: null,
        items: {}
      }
    };

  case ADD_SELECTED_PIN:
    newState = state;
    newState.selectedPinIds[action.id] = true;

    return {...newState, error: null};
    
  case REMOVE_SELECTED_PIN:
    newState = state;
    delete newState.selectedPinIds[action.id];
    return {...newState, error: null};

  case CLEAR_ALL_SELECTED_PINS:
    items = state.items;
    Object.keys(state.selectedPinIds).forEach(function (key) {
      delete items[key];
    });
    
    return {
      ...state,
      items: {...items},
      isLoading: false,
      error: null,
      selectedPinIds: [],
    };

  case ADD_FILTER:
    filters = state.filters;
    if(action.name && action.value && filters[action.name] && filters[action.name].indexOf(action.value) === -1) {
      filters[action.name].push(action.value);
      storeFilters(filters);
    }
    return {
      ...state,
      error: null,
      filters
    };

  case REMOVE_FILTER:
    filters = state.filters;
    filters[action.name] = filters[action.name].filter(item => item !== action.value);
    storeFilters(filters);
    return {
      ...state,
      error: null,
      filters
    };

  case CLEAR_FILTERS:
    filters = {
      site: []
    };
    storeFilters(filters);
    return {
      ...state,
      error: null,
      filters
    };

  case READ_SETTINGS_FROM_LOCALSTORAGE:
    return {
      ...state,
      filters: getFiltersFromStore(state.filters)
    };

  default:
    return state;
  }
};
