import React from 'react';
import {useDispatch,useSelector} from 'react-redux';


import {setFileState,uploadACIngestFile,setError,setAudience,ingestACFile,setFieldError,setShowSummary} from './actions';
import Modal from './Modal';
import Alert from '../Utils/AlertDismissible';
import Summary from './Summary';

export default ()=>{

    const dispatch = useDispatch();
    const fileObj   = useSelector(state=>state.acIngest.acIngestFile);
    const error   = useSelector(state=>state.acIngest.error);
    const errorVariant   = useSelector(state=>state.acIngest.errorVariant);
    const isUploaded   = useSelector(state=>state.acIngest.isUploaded);
    const audience   = useSelector(state=>state.acIngest.audience);
    const fieldError   = useSelector(state=>state.acIngest.fieldError);
    const isShowSummary   = useSelector(state=>state.acIngest.isShowSummary);
    const resourceId = useSelector(state=>state.acIngest.resourceId);
    const {showModal,ImportStatus,importType} = useSelector(state=>state.acIngest.modalInfo);
    
    const onFileSelect=(ev)=>{
        dispatch(setFileState(ev.target.files[0]));
        dispatch(setError(''));
        dispatch(setFieldError('','file')); 
    }

    const uploadFile = ()=>{ 
        dispatch(setShowSummary(false));
        dispatch(setError(''));
        const isFileError = validateFile(fileObj);
        if(!isFileError)
            dispatch(uploadACIngestFile())        
    }

    const validateFile = (fileObj)=>{
        let errorMsg,fieldName;
        let isFileError = false;
       
        if(!audience){
            isFileError =true;
            fieldName = 'audience';
            errorMsg = 'Please select Audience';
            dispatch(setFieldError(errorMsg,fieldName)); 
        }
        
        if(fileObj.length===0){
            isFileError =true;
            fieldName = 'file';
            errorMsg = 'Please select a zip file';
        }
        else{
            const fileExtn = fileObj.name.split('.').reverse()[0];
            if(fileExtn.toLowerCase() !== 'zip'){
                isFileError =true;
                fieldName = 'file';
                errorMsg = 'Only zip file allowed';
            }else if(fileObj.size/(1024*1024) > 2048){
                isFileError =true;
                fieldName = 'file';
                errorMsg = 'File size cannot be greater than: 2GB';    
            }               
        }

        if(isFileError)
            dispatch(setFieldError(errorMsg,fieldName)); 
        
        return isFileError;    
    }

    const onAlertClose=()=>{
        dispatch(setError(''));
    }

    const ingestFile=()=>{
        dispatch(setShowSummary(false));
        const isFileError = validateFile(fileObj);
        if(!isFileError){
            if(isUploaded)
                dispatch(ingestACFile());
            else{
                let errorMsg = 'Upload ZIP file before ingesting content';
                dispatch(setError(errorMsg));
            }
        }
    }

    const onChangeAudience=(ev)=>{
        dispatch(setFieldError('','audience')); 
        dispatch(setAudience(ev.target.value))
    }

    return(
        <div className="file-upload-container">  
            <div>
                <div className="row1">
                    <div style={{width:"460px"}} >
                        {error && <Alert variant={errorVariant} alertMsg={error} onAlertClose={onAlertClose}/>}
                    </div>
                </div>
                <div className="audience-div">
                    <label className="label-audience" style={{color:fieldError.audience && "#FF5252" }}>Audience</label>
                    <select className="form-control audience-type" onChange={(ev)=>onChangeAudience(ev)} value={audience} style={{borderColor:fieldError.audience && "#FF5252",color:fieldError.audience && "#FF5252"}}>
                        <option value=''>Select Audience</option>
                        <option value='book-owner-resources'>Book Owner</option>
                        <option value='teacher-resources'>Teacher</option>
                    </select>
                    <div className="audience-error">
                        {fieldError.audience && <small className="text-danger">{fieldError.audience}</small>}
                    </div> 
                   
                </div>

                <div>
                    <span className="file-instruction">Select and upload file to be ingested</span>&nbsp;
                    <div className="popover__wrapper">
                        <div className="popover__content modal11 midal-instruction">
                            <div className="modal-content1" >
                                <div className="modal-body1">
                                Only ZIP files allowed. Use the Ancillary Content Builder Standalone tool to bundle the assets into ZIP.
                                </div>
                            </div>
                        </div> 
                        <img alt="info-icon" src="assets/images/info.svg" className="popover__title"/>
                        
                    </div>
                </div>    
                





                <div>
                    <div className="file-upload-div" style={{border:fieldError.file && "1px solid #FF5252" }}>

                        {<button id="span_up" className="upload-btn fileinput-button"><img  src="/assets/images/upload.svg" alt="upload" style={{marginBottom:"4px"}}/>&nbsp;
                            Select Zip File
                            <input name="filesToUpload" type="file" id="main_uploader" className="uploader" onChange={onFileSelect} accept=".zip"/>
                        </button>}&nbsp;
                    <span className="file-name"><u>{fileObj && fileObj.name}</u></span>
                        
                    </div>
                    <div>
                        <button
                                type="button"
                                aria-label="Submit PII"
                                className="btn importBtn"
                                onClick={uploadFile}
                            >
                            Upload File
                        </button>
                    </div>
                </div>    
            </div> 
            <div className="file-error">
                {fieldError.file && <small className="text-danger">{fieldError.file}</small>}
            </div>   
            <br/>
             
            <div>
                <button
                        type="button"
                        aria-label="Submit PII"
                        className="btn ingestBtn"
                        onClick={ingestFile}
                    >
                    Ingest Content
                </button>
            </div> 
            
            <input type="hidden" className="resourceId" value={resourceId} />
        	{isShowSummary && <Summary/>}
            {showModal && <Modal ImportStatus={ImportStatus} importType={importType} fileName={fileObj.name}/>}  
        </div>
    )
}