import React from "react";

import UserInformation from './UserInformation';
import PermissionFilters from './PermissionFilters';
import ViewEntitlements from './ViewEntitlements'
import './index.scss';

export default () => {
  return (
    <div className="add-permission-container">
        <UserInformation/>
        <div style={{marginTop:'48px'}} > 
            <PermissionFilters/>
        </div> 
        <div style={{marginTop:'48px'}}> 
            <ViewEntitlements/>
        </div> 
    </div>
  );
};
