import React from 'react';
import {useDispatch,useSelector} from 'react-redux';


import {setFileState, importPins,setAlertDATA, setFileError} from './actions';
import Modal from './Modal';
import ImportSummary from './ImportSummary';
import CSVFile from  './PIN-manifest.csv';
import Alert from '../Utils/AlertDismissible';
//import sprite from '../../../../public/assets/images/sprite.svg';

export default ()=>{
    
    const dispatch = useDispatch();
    const fileObj = useSelector(state=>state.pinInfo.pinFile);
    const alertMsg = useSelector(state=>state.pinInfo.alertMsg);
    const alertType = useSelector(state=>state.pinInfo.alertType);
    const showSummary = useSelector(state=>state.pinInfo.showSummary);
    const resourceId = useSelector(state=>state.pinInfo.resourceId);
    const showModal =  useSelector(state => state.pinInfo.showModal);
    const importStatus = useSelector(state=>state.pinInfo.importStatus);
    const {isFormatError,isSizeError,showFileError} = useSelector(state=>state.pinInfo.fileError);
    
    const importsPins = ()=>{
                
        const isFileError = validateFile(fileObj);
        if(!isFileError)
            dispatch(importPins())
    }

    const validateFile = (fileObj)=>{
        let errorMsg;
        let isFormatError = false;
        let isSizeError = false;
        let isFileError =false;

        if(fileObj.length===0){
            isFileError =true;
            errorMsg = 'You did not select a file to upload';
            dispatch(setAlertDATA(errorMsg,'danger'));
        }
        else{
            const fileExtn = fileObj.name.split('.').reverse()[0];
            if(fileExtn.toLowerCase() !== 'csv')
                isFormatError = true;
            if(fileObj.size/(1024*1024) > 5)
                isSizeError = true;    
                
            dispatch(setFileError(isFormatError,isSizeError,true));    
        }
        
        
        if(isFormatError || isSizeError)
            isFileError =true;
        
        return isFileError;    
    }

    const onFileSelect=(ev)=>{
        dispatch(setFileState(ev.target.files[0]));
        dispatch(setFileError(false,false,false));
    }
    
    const onAlertClose=()=>{
        dispatch(setAlertDATA(''));
    }

    return(
        <div className="file-upload-container">
            <div className="row1">
                <div style={{width:"635px"}} >
                    {alertMsg && <Alert variant={alertType} alertMsg={alertMsg} onAlertClose={onAlertClose}/>}
                    </div>
                </div>
            <div>
                <div className="file-upload-div" style={{textAlign:'center',paddingTop:'58px'}}>
                    {/*<button
                            type="button"
                            aria-label="Search PII"
                            className="searchBt"
                        >
                        Upload CSV File
                    </button>*/}
                    {<button id="span_up" className="upload-btn fileinput-button"><img  src="/assets/images/upload.svg" alt="upload" style={{marginBottom:"4px"}}/>&nbsp;
                        Select CSV File
                        <input name="filesToUpload" type="file" id="main_uploader" className="uploader" onChange={onFileSelect} accept=".csv"/>
                    </button>}
                    <br/>
                   <span className="file-name">{fileObj && fileObj.name}</span>
                    
                </div>
                

                <div className='file-instruction'>
                    <div className="instruction-logo">
                        {showFileError && !isFormatError && <img  src="/assets/images/success-tick.svg" alt="success" className="error-img"/>}
                        {showFileError && isFormatError &&  <img  src="/assets/images/error-cross.svg" alt="error" className="error-img"/>}
                    </div>    
                    <div>
                        <p>
                            Only correctly formatted CSV files allowed<br/><a href={CSVFile} download="PIN_CODE_CSV_TEMPLATE.csv" >(<u>Download CSV Template</u>)</a>
                        </p>
                    </div> 
                    
                    <div className="instruction-logo">
                        {showFileError && !isSizeError && <img  src="/assets/images/success-tick.svg" alt="success" className="error-img"/>}
                        {showFileError && isSizeError &&<img  src="/assets/images/error-cross.svg" alt="error" className="error-img"/>}
                    </div>       
                    <div>
                        <p>
                            5MB Max file size.<br/>If it exceeds 5MB, divide into multiple files and import individually.
                        </p>
                    </div>    
                </div>
            </div> 
            
            <div>
                <button
                      type="button"
                      aria-label="Submit PII"
                      className="btn importBtn"
                      onClick={importsPins}
                  >
                Import PINs
              </button>
            </div> 
            <input type="hidden" className="resourceId" value={resourceId} />
            {showSummary && <ImportSummary/>}      
            {showModal && <Modal importStatus={importStatus}/>}  
        </div>
    )
}