import React from "react";
import Modal from '../Modal';
import ErrorMessage from '../Utils/Error/Message';
import Loading from '../Utils/Loading';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const UserInfoModal = ({show, setShow}) => {
  const items = useSelector(state => state.pin.selectedUser.items);
  const isLoading = useSelector(state => state.pin.selectedUser.isLoading);
  const error = useSelector(state => state.pin.selectedUser.error);
  const email = useSelector(state => state.pin.selectedUser.email);
  const name = useSelector(state => state.pin.selectedUser.name);
  const rows = [];

  Object.values(items).forEach(function (item, key) {
    rows.push(
      <tr key={item.id} id={'usr-' + item.id}>
        <td className="publication">{item.publicationTitle}</td>
        <td className="isbn">{item.contentId}</td>
        <td className="status">{item.status}</td>
        <td className="created">{item.createdDate}</td>
      </tr>
    );
  });

  return (
    <>
      <Modal title="User Information" show={show} setShow={setShow}>
        <div className="row user-info">
          <div className="col-12">
            <div className="user-info__titles mt-2 mb-4">
              <table>
                <tbody>
                  <tr><td className="text-secondary font-weight-bold pr-2 name-label">Name</td><td className="name-txt">{name}</td></tr>
                  <tr><td className="text-secondary font-weight-bold pr-2 email-label">Email</td><td className="email-txt">{email}</td></tr>
                </tbody>
              </table>
            </div>
            {error && <div className="user-info__error"><ErrorMessage error={error} /></div>}
            <table className="table table-sm user-info__table">
              <thead className="table-dark bg-secondary">
                <tr>
                  <th className="publication">Publication</th>
                  <th className="isbn">ISBN</th>
                  <th className="status">Status</th>
                  <th className="created">Requested Date</th>
                </tr>
              </thead>
              <tbody>
                {isLoading === true &&
                  <tr className="loading">
                    <td colSpan="4" className="text-center">
                      <Loading />
                    </td>
                  </tr>
                }
                {isLoading !== true &&
                  rows
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};


UserInfoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired
};

export default UserInfoModal;
