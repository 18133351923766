import React from "react";
import SCIMDemo from '../../modules/SCIMDemo';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getSCIMInfo } from '../../modules/SCIMDemo/actions';

export default () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSCIMInfo());
  });

  return <>
    <h2 className="page-title">SCIM Demo</h2>
    <SCIMDemo />
  </>;
};
