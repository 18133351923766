import React from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

export default () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const showStatusWarning = useSelector(state => state.menu.showStatusWarning);
  return isAuthenticated && showStatusWarning ? (
    <Link to="/" className="text-danger system-status">
      <img className="znr-system-status-link" src="/assets/icons/gizmo-information.svg" alt="System Status Warning"/>
      Service Issues Reported
    </Link>
  ) : null;
};
