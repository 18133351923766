import {SET_USER_LIST, SET_PIN_LOADING, SET_PIN_ERROR, SET_ENTITLEMENT_LIST,SET_USER_INFO,CLEAR_STATE} from '../actions';

export const initialState={
    users:[],
    totalCount:0,
    isLoading:false,
    error:null,
    entitlements:[],
    userInfo:[]
}

export default (state={...initialState},action={})=>{
    switch(action.type){
        case SET_USER_LIST: 
            return {
                ...state,
                users:action.users,
                totalCount: action.totalCount,
                isLoading:false
            }
        
        case SET_ENTITLEMENT_LIST:
            return{
                ...state,
                entitlements : action.entitlements,
            }   

        case SET_PIN_LOADING :
            return {
                ...state,
                isLoading: true,
            }; 
        
        case SET_PIN_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
            
        case SET_USER_INFO:
            return{
                ...state,
                isLoading: false,
                userInfo: action.userInfo
            }

        case CLEAR_STATE:
                return initialState;
            
        default:
                return state;          
    }
}
