import React, {useState} from "react";
import Modal from '../../Modal';
import Message from './Message';
import PropTypes from 'prop-types';

const Error = ({error}) => {
  const [show, setShow] = useState(() => error ? true : false);

  return (
    <Modal title="Error" show={show} setShow={setShow} closebtntxt="OK">
      <Message error={error} />
    </Modal>
  );
};


Error.propTypes = {
  error: PropTypes.string.isRequired
};

export default Error;
