import React from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import SecureRoute from './SecureRoute';

import Home from './Home';
import Login from './Login';
import Error404 from './Error404';
import LostAndStolenPin from './LostOrStolenPin';
import UserManagement from './UserManagement';
import ArticleIngestion from './ArticleIngestion';
import AddPermission from './AddPermission';
import PinImport from './PinImport';
import ACIngest from './ACIngest';
import SCIMDemo from './SCIMDemo';
import { getMenu } from '../modules/Menu/actions';
//import { getPinList } from '../modules/PinList/actions';


const Routes = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  if(isAuthenticated === true) {
    dispatch(getMenu());
    //dispatch(getPinList()); //this call is here to wakeup the server but should be removed in future as it is a wasted call
  }

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <SecureRoute exact path="/lost-or-stolen-pin" component={LostAndStolenPin} />
      <SecureRoute exact path="/" component={Home} />
      <SecureRoute exact path="/user-management" component={UserManagement} />
      <SecureRoute exact path="/article-ingestion" component={ArticleIngestion} />
      <SecureRoute exact path="/add-permission/:userId" component={AddPermission} />
      <SecureRoute exact path="/pin-import" component={PinImport} />
      <SecureRoute exact path="/ac-ingest" component={ACIngest} />
      <SecureRoute exact path="/scim-demo" component={SCIMDemo} />
      <Route component={Error404} />
    </Switch>
  );
};

export default Routes;
