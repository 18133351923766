import React from 'react';

import FileUpload from './FileUpload';
import './index.scss';

export default ()=>{
    return(
        <div className='ac-ingest-container'>
            <FileUpload/>
        </div>   
    )
}