import React from 'react';
import {useDispatch} from 'react-redux';

import {setModalData,setShowSummary} from './actions';

export default ({importStatus})=>{
    const dispatch = useDispatch();

    let modalTitle,modalMsg,showSummaryBtn;
    if(importStatus === 'inprogress'){
        modalTitle = 'Importing PIN Codes';
        modalMsg='Please wait while the PIN Codes are being imported from the uploaded CSV file.';

    }else if(importStatus === 'done'){
        modalTitle = 'Import Completed';
        modalMsg='PIN Codes import process is completed.';
        showSummaryBtn = true;
    }else{
        modalTitle = 'Processing Error';
        modalMsg='There was Processing Error , please contact support team.';
        showSummaryBtn = false;
    }

    const closeModal = ()=>{
        dispatch(setModalData(false));
    }

    const viewSummary=()=>{
        dispatch(setModalData(false));
        dispatch(setShowSummary(true));
    }

    return(
        <div id="myModal" className="modal1">
            <div className="modal-content1" >
                <div className="modal-body1">
                    <span className="close" onClick={closeModal}>&times;</span>
                    <p className="modal-title1">
                        {importStatus==='done' && <img src="/assets/images/success-tick.svg" alt="success"  />}
                       {importStatus==='inprogress' && <img src="/assets/images/spin-loader.svg" alt="loader" className="rotate"/>}
                       {importStatus==='processing_error' && <img src="/assets/images/error-cross.svg" alt="error"/>}
                        &nbsp;&nbsp;{modalTitle}
                    </p>
                    <p className="popup-msg">{modalMsg}</p>
                    <div className="modal-btn">
                        {showSummaryBtn &&<button
                            type="button"
                            aria-label="Submit PII"
                            className="btn replaceBtn"
                            onClick={viewSummary}
                           >
                            View Import Summary
                        </button>}
                    </div>
                </div>
            </div>
      </div>
    );
}