import fetchApi from '../../../services/ApiService';
export const SET_SCIM_INFO = 'SET_SCIM_INFO';
export const SET_SCIM_ERROR = 'SET_SCIM_ERROR';
export const SET_SCIM_LOADING = 'SET_SCIM_LOADING';

export const getSCIMInfo = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.accessToken;

    if(token) {

      dispatch(setSCIMLoading());

      const userId = getState().auth.userId;
      const url = new URL(`${process.env.REACT_APP_SCIM_USER_INFO_URL}${userId}`);

      let response;
      try {
        response = await fetchApi(url, {
          method: 'GET',
          headers: {
            'Authorization': 'bearer ' + token
          }
        });
      } catch(e) {

      };
      if(response && response.ok) {
        let data = await response.json();

        if(data.error === undefined) {
          dispatch(setSCIMInfo(data));
        } else {
          dispatch(setSCIMError(data.error));
        }
      } else {
        dispatch(setSCIMError('something went wrong please check page later'));
      }
    }


  };
};

export const setSCIMInfo = (info) => {
  return { type: SET_SCIM_INFO, info };
};

export const setSCIMError = (error) => {
  return { type: SET_SCIM_ERROR, error };
};

export const setSCIMLoading = () => {
  return { type: SET_SCIM_LOADING };
};
