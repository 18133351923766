import { SET_SCIM_INFO, SET_SCIM_ERROR, SET_SCIM_LOADING } from '../actions';

export const initState = {
  info: null,
  error: null,
  isLoading: false
};

export default (state = initState, action = {}) => {

  switch (action.type) {

  case SET_SCIM_INFO:
    return {
      info: action.info,
      isLoading: false,
      error: null
    };

  case SET_SCIM_ERROR:
    return {
      ...state,
      isLoading: false,
      error: action.error
    };

  case SET_SCIM_LOADING:
    return {
      info: null,
      isLoading: true,
      error: null,
    };

  default:
    return state;
  }
};
