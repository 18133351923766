import React, {useState} from "react";
import PropTypes from 'prop-types';

const Pagination = ({pageChanged, totalPages}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pages = [];

  const changePage = (selectedPage) => {
    setCurrentPage(selectedPage);
    pageChanged(selectedPage);
  };

  const nextClicked = () => {
    const page = currentPage + 1;
    setCurrentPage(page);
    pageChanged(page);
  };

  const previousClicked = () => {
    const page = currentPage - 1;
    setCurrentPage(page);
    pageChanged(page);
  };

  for(let i = 1; i <= totalPages; i++) {
    pages.push(
      <li className={'page-' + i + ' page-item' + (currentPage === i ? " disabled" : "")} key={i} >
        <button className="page-link" onClick={() => changePage(i)}>{i}</button>
      </li>
    );
  }

  return (
    <nav aria-label="page navigation">
      { totalPages > 0 &&
        <ul className="pagination">
          <li className={"page-item prev" + (currentPage === 1 ? " disabled" : "")}>
            <button className="page-link" tabIndex="-1" onClick={() => previousClicked()}>&lt;</button>
          </li>
          {pages}
          <li className={"page-item next " + (currentPage === totalPages ? " disabled" : "")}>
            <button className="page-link" onClick={() => nextClicked()}>&gt;</button>
          </li>
        </ul>
      }
    </nav>
  );
};

Pagination.propTypes = {
  pageChanged: PropTypes.func.isRequired,
  totalPages: PropTypes.number
};

export default Pagination;
