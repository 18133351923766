import React from "react";
//import {Link} from "react-router-dom";
import {useSelector} from 'react-redux';
import moment  from 'moment';

export default () => {
    const userInfo = useSelector(state=>state.user.userInfo);   
    return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {<a style={{ color: "#336699", fontSize: "14px" }} href="../user-management">Back</a>}
          <span
            style={{
              fontSize: "20px",
              marginLeft: "12px",
              marginRight: "12px"
            }}
          >
            |
          </span>
          <span style={{ fontSize: "20px", color: "rgba(51, 51, 51, 0.89)" }}>
            Permissions for {userInfo.givenName} {userInfo.familyName}
          </span>
        </div>
      </div>
      <div className="user-information">
        <div className="row">
          <div
            className="col-12"
            style={{ fontSize: "14px", fontWeight: 600, marginBottom: "5px" }}
          >
            User Information
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <span>
              Email ID : <span className="data" style={{ color: "#336699" }}>
                 <u>{userInfo.email}</u>
                 {userInfo.emailVerified===true && <img  src="/assets/images/verified-logo.svg" className="verified-img" alt="verified" data-toggle="popover" title="Verified" />}
              </span>
            </span>
          </div>
          <div className="col-3">
            <span>
              First Name : <span className="data">{userInfo.givenName}</span>
            </span>
          </div>
          <div className="col-3">
            <span>
              Last Name : <span className="data">{userInfo.familyName}</span>
            </span>
          </div>
          <div className="col-3">
            <span>
              Registered On : <span className="data">{moment(userInfo.created).format('DD MMM YYYY')}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
