import fetchApi from '../../../services/ApiService';

//basic pin actions
export const SET_PIN_LIST = 'SET_PIN_LIST';
export const SET_PIN_ERROR = 'SET_PIN_ERROR';
export const SET_PIN_LOADING = 'SET_PIN_LOADING';
export const ADD_SELECTED_PIN = 'ADD_SELECTED_PIN';
export const REMOVE_SELECTED_PIN = 'REMOVE_SELECTED_PIN';
export const CLEAR_ALL_SELECTED_PINS = 'CLEAR_ALL_SELECTED_PINS';

//filter actions
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const READ_SETTINGS_FROM_LOCALSTORAGE = 'READ_SETTINGS_FROM_LOCALSTORAGE';
export const FILTER_STORAGE_NAME = 'znr.pin.filters';

//statuses
export const PIN_STATUS_INITIAL = 'Initial';
export const PIN_STATUS_ACCEPTED = 'Accepted';
export const PIN_STATUS_REJECTED = 'Rejected';

//user actions
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const SET_USER_LOADING = 'SET_USER_LOADING';
export const SET_USER_ERROR = 'SET_USER_ERROR';

export const SITE_FILTER_NAME = 'site';
export const SITE_TAG = 'Site:';

export const PIN_ERROR = 'something went wrong please check page again later';
export const DUPLICATE_PIN_ERROR = 'cannot add duplicate filter';
export const UNAUTHORIZED_ERROR = 'You are not authorized to perform this operation';

//Pagination
export const PINS_PER_PAGE = 20;


export const getPinList = (status = PIN_STATUS_INITIAL, requesterId = null, pageNo = 1) => {
  return async (dispatch, getState) => {

    const token = getState().auth.accessToken;

    if(token) {

      //const userId = getState().auth.userId;No need to pass userId in V2 entitlement endpoint
      const sites = getState().pin.filters.site;
      const isSetUserData = requesterId ? true : false;
      const startIndex = (pageNo - 1) * PINS_PER_PAGE;

      if(isSetUserData) {
        dispatch(setUserLoading());
      } else {
        dispatch(setLoading());
      }

      const url = new URL(`${process.env.REACT_APP_ENTITLEMENT_BASE_URL}/entitlement-requests`);
      //url.searchParams.append('userId', userId);// No need to pass userId in V2 entitlement endpoint

      if(isSetUserData) {
        url.searchParams.append('requesterId', requesterId);
        url.searchParams.append('limit', '50');
        url.searchParams.append('offset', '0');
      } else {
        url.searchParams.append('status', status);
        if(sites.length) {
          const siteQuery = sites.join(',');
          url.searchParams.append('productId', siteQuery);
        }
        url.searchParams.append('limit', PINS_PER_PAGE);
        url.searchParams.append('offset', startIndex);
      }
      
      let response;
      try {
        response = await fetchApi(url, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer '+token
          }
        });
      } catch(e) {

      };
      if(response && response.ok) {
        const data = await response.json();
        if(isSetUserData) {
          dispatch(setUserInformation(data.entitlementRequests));
        } else {
          dispatch(setPinList(data.entitlementRequests, data.total));
        }
      } else {

        await dispatch(setError(''));
        if(isSetUserData) {
          dispatch(setUserError(PIN_ERROR));
        } else {
            const statusCode = response && response.status;
            
            let error;
            if(statusCode === 403)
              error = UNAUTHORIZED_ERROR;
            else
              error = PIN_ERROR;
            dispatch(setError(error));
        }
      }
    }
  };
};

export const putStatus = (action = PIN_STATUS_ACCEPTED) => {
  return async (dispatch, getState) => {
    const token = getState().auth.accessToken;
    if(token) {
      //const userId = getState().auth.userId;//No need to pass userId in V2 entitlement endpoint
      const url = new URL(`${process.env.REACT_APP_ENTITLEMENT_BASE_URL}/entitlement-requests`);
      //url.searchParams.append('userId', userId); //No need to pass userId in V2 entitlement endpoint

      //const items = getState().pin.items;
      const data = [];
      const selectedIds = getState().pin.selectedPinIds;
      
      Object.keys(selectedIds).forEach(function (key) {
        data.push({
          id: key,
          reason: 'DU',
          status: action,  
        });
      });

      let response;
      try {
        response = await fetchApi(url,
          {
            method: 'PATCH',
            headers:
            {
              'Authorization': 'Bearer '+token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }
        );
      } catch(e) {

      };

      if(response && response.ok) {
        dispatch(clearSelectedPins());
      } else {
        
        await dispatch(setError(''));
        
        const statusCode = response && response.status;
        let error;
        if(statusCode === 403)
          error = UNAUTHORIZED_ERROR;
        else
          error = PIN_ERROR;
        
        dispatch(setError(error));
      }
    }
  };
};

export const setPinList = (items, totalCount = 0) => {
  return { type: SET_PIN_LIST, items, totalCount };
};

export const setUserInformation = (items) => {
  return { type: SET_USER_INFORMATION, items };
};

export const setError = (error) => {
  return { type: SET_PIN_ERROR, error };
};

export const setUserError = (error) => {
  return { type: SET_USER_ERROR, error };
};

export const setLoading = () => {
  return { type: SET_PIN_LOADING };
};

export const setUserLoading = () => {
  return { type: SET_USER_LOADING };
};

export const addSelectedPin = (id) => {
  return { type: ADD_SELECTED_PIN, id };
};

export const removeSelectedPin = (id) => {
  return { type: REMOVE_SELECTED_PIN, id };
};

export const clearSelectedPins = () => {
  return { type: CLEAR_ALL_SELECTED_PINS};
};

export const addFilter = (name, value) => {
  return { type: ADD_FILTER, name, value };
};

export const removeFilter = (name, value) => {
  return { type: REMOVE_FILTER, name, value };
};

export const clearFilters = () => {
  return { type: CLEAR_FILTERS};
};

export const readSettingsFromLocalStorage = () => {
  return { type: READ_SETTINGS_FROM_LOCALSTORAGE};
};
