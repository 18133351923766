import React from 'react';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import PinImport from '../../modules/PinImport';
import {clearState} from '../../modules/PinImport/actions';

export default ()=>{

    const dispatch = useDispatch();
    useEffect(() => {
      return function cleanUpState(){
        dispatch(clearState());
      };
    });

    return(
        <div>
            <h2 className="page-title">PIN Import</h2>
            <PinImport/>
        </div>
    );

}